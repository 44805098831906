import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, MenuItem, Select, FormControl, InputLabel, Box, ToggleButton, ToggleButtonGroup, Grid, Typography, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { matchEntityCountries } from '../guestUser/matchEntityCountries';
import Header from '../common/Header';
import Footer from '../common/Footer';
import "./style.css";

const Step1 = ({ nextStep, handleChange, data }) => {
  const [formType, setFormType] = useState(data.formType || 'Person');
  const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();

  useEffect(() => {
    for (const [key, value] of Object.entries(data)) {
      setValue(key, value);
    }
  }, [data, setValue]);

  const sortedMatchEntityCountries = matchEntityCountries.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  const handleFormTypeChange = (event, newFormType) => {
    if (newFormType !== null) {
      setFormType(newFormType.charAt(0).toUpperCase() + newFormType.slice(1));
    }
  };

  const onSubmit = (formData) => {
    handleChange(1, { ...formData, formType });
    nextStep();
  };

  return (
    <div>
      <div className="landing-wrapper">
        <Header />
        <div className="container">
          <section className="bg-white dark:bg-gray-300">
            <Container elevation={3} sx={{ p: 4, mt: 6 }}>
              <Typography variant="h1" gutterBottom align="center" >
                Pay As You Go
              </Typography>
              <Typography variant="h4">Screening Information</Typography>
              <Box component="form" sx={{ '& .MuiTextField-root': { m: 2 } }} onSubmit={handleSubmit(onSubmit)}>
                <ToggleButtonGroup
                  value={formType.toLowerCase()}
                  exclusive
                  onChange={handleFormTypeChange}
                  aria-label="form type"
                  sx={{ mb: 2 }}
                >
                  <ToggleButton value="person" aria-label="person">
                    Individual
                  </ToggleButton>
                  <ToggleButton value="organization" aria-label="organization">
                    Entity
                  </ToggleButton>
                </ToggleButtonGroup>
                {formType === 'Person' && (
                  <Grid container spacing={3} alignItems='flex-start'>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="First Name"
                          variant="outlined"
                          {...register('firstName', { required: 'First Name is required', minLength: { value: 3, message: 'First Name must be at least 3 characters' } })}
                          error={!!errors.firstName}
                          helperText={errors.firstName ? errors.firstName.message : ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Middle Name"
                          variant="outlined"
                          {...register('middleName', { minLength: { value: 3, message: 'Middle Name must be at least 3 characters' } })}
                          error={!!errors.middleName}
                          helperText={errors.middleName ? errors.middleName.message : ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          {...register('lastName', { required: 'Last Name is required', minLength: { value: 3, message: 'Last Name must be at least 3 characters' } })}
                          error={!!errors.lastName}
                          helperText={errors.lastName ? errors.lastName.message : ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <TextField
                          label="Date of Birth"
                          variant="outlined"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          {...register('dateOfBirth')}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          label="Gender"
                          variant="outlined"
                          defaultValue=""
                          {...register('gender')}
                        >
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Controller
                          name="nationality"
                          control={control}
                          defaultValue={null}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Autocomplete
                              options={sortedMatchEntityCountries}
                              getOptionLabel={(option) => option.label}
                              value={sortedMatchEntityCountries.find((country) => country.label === value) || null}
                              onChange={(_, newValue) => {
                                onChange(newValue ? newValue.label : '');
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Country of Residence"
                                  variant="outlined"
                                  onBlur={onBlur}
                                  inputRef={ref}
                                />
                              )}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {formType === 'Organization' && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Organization Name"
                          variant="outlined"
                          {...register('name', { required: 'Organization Name is required', minLength: { value: 3, message: 'Organization Name must be at least 3 characters' } })}
                          error={!!errors.name}
                          helperText={errors.name ? errors.name.message : ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Controller
                          name="nationality"
                          control={control}
                          defaultValue={null}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <>
                              <Autocomplete
                                options={sortedMatchEntityCountries}
                                getOptionLabel={(option) => option.label}
                                value={sortedMatchEntityCountries.find((country) => country.label === value) || null}
                                onChange={(_, newValue) => {
                                  onChange(newValue ? newValue.label : '');
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Country"
                                    variant="outlined"
                                    onBlur={onBlur}
                                    inputRef={ref}
                                  />
                                )}
                              />
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Button variant="contained" type="submit" sx={{ mt: 2 }} className="button cc-small w-button">
                  Continue
                </Button>
              </Box>
            </Container>
          </section>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </div >

  );
};

export default Step1;
