import {
  Button,
  Descriptions,
  Input,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Table,
  Typography,
  message,
} from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import SampeBulkAddClients from "../../../assets/Samples/SampeBulkAddClients.xlsx";
import { selectLoading } from "../../../redux/auth/selectors";
import {
  default as Client,
  default as ClientService,
} from "../../../service/Client";
import { handleXLSXTOJSON } from "../../../utils/bulkUpload";
import User from "../../../service/User";

export const exportToExcel = (data) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  XLSX.writeFile(workbook, "data.xlsx");
};
const EditGroupPage = () => {
  const canEdit = (() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken);
    return decoded.hasFullClientAccess;
  })();
  const [duplicateClient, setDuplicateClient] = useState(false);
  const [displayDeleted, setDisplayDeleted] = useState(false);
  const [deletedClients, setDeletedClients] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [clientData, setClientData] = useState({});
  const [displayArchive, setDisplayArchive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const fields = [
    { name: "name", placeholder: "Name" },
    { name: "isBusiness", placeholder: "Entity or Individual" },
    { name: "clientWebsite", placeholder: "Website" },
    { name: "complianceNote", placeholder: "Compliance Note" },
    { name: "country", placeholder: "Country" },
    { name: "ongoingScreening", placeholder: "Ongoing Screening" },
  ];
  const [errorModal, setErrorModal] = useState(false);
  const [Clients, setClients] = useState([]);
  const loading = useSelector(selectLoading);

  const loadClients = useCallback(async () => {
    const response = await Client.getClientsByUser(id);
    setClients(
      response.data
        .filter((t) => t.group === id)
        .map((t) => ({ ...t, key: t?._id }))
    );
  }, [id]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const handleDelete = async (data, revoke) => {
    await Client. softDeleteClient({
      clientId: data._id,
      revoked: !revoke,
    }).then(() => {loadClients(); fetchDeletedClients()});
  };

  const { clientId } = useParams();
  const [visible, setVisible] = useState(false);
  const [handleCustomScreeningData, setHandleCustomScreeningData] =
    useState(null);
  const handleYes = async () => {
    await Client.editClient(handleCustomScreeningData);
    setVisible(false);
  };

  const handleNo = () => {
    setClientData((prev) => {
      return { ...prev, ongoingScreening: !clientData.ongoingScreening };
    });
    setVisible(false);
  };

  const handleScreeningChange = (e) => {
    setClientData((prev) => {
      // if (!prev) return { ongoingScreening: e, screeningFlag: e }; // Handle null case
      if (e === prev.ongoingScreening) return prev;
      else {
        EditClient({ ...prev, ongoingScreening: e, screeningCustomFlag: true });
        return { ...prev, ongoingScreening: e, screeningFlag: e };
      }
    });
  };

  const EditClient = async (data) => {
    if (!canEdit) return message.error("You have read only access");
    if (data.screeningCustomFlag) {
      const flagResult = await Client.getClientFlag({
        clientId: clientId,
      });
      const resultFlag = flagResult.data.result;
      const limitFlag = flagResult.data.limit;
      if (limitFlag) {
        if (resultFlag) {
          setVisible(true);
          setHandleCustomScreeningData({
            clientId: clientId,
            ...data,
            relatedParties: undefined,
            resultFlag: resultFlag,
          });
        } else {
          await Client.editClient({
            clientId: clientId,
            ...data,
            relatedParties: undefined,
          });
        }
      } else {
        return message.error(
          "You have reached your maximum screening limit. To add more clients to the screening, increase the license limit by purchase"
        );
      }
    } else {
      await Client.editClient({
        clientId: clientId,
        ...data,
        relatedParties: undefined,
      });
    }
  };

  const columns = [
    
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Ongoing Screening",
    //   dataIndex: "ongoingScreening",
    //   key: "ongoingScreening",
    //   render: (text) => (
    //     <Switch checked={text} onChange={handleScreeningChange} />
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Link to={`/app/clients/related-parties/${text._id}`}>
            <Button type="primary">Details</Button>
          </Link>
          {record.finalAssessmentId && (
            <Link to={`/app/periodicreview/${record.finalAssessmentId}/x`}>
              <Button type="primary">Periodic Review</Button>
            </Link>
          )}

          {canEdit && (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleDelete(text, !displayDeleted)}
            >
              <Button danger>{displayDeleted?"Revoke":"Delete"}</Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const [AddData, setAddData] = useState({
    group: id,
    name: "",
    isBusiness: false,
    clientWebsite: "",
    complianceNote: "",
    status: "",
    relatedParties: [],
    ongoingScreening: false,
    screeningFlag: false,
  });

  const EditClientGroup = async (data) => {
    await Client.editClientGroup({
      clientGroupId: id,
      ...data,
    });
  };

  const Add = async (a) => {
    try {
      const response = await Client.createClient({ ...AddData, isExist: a });

      if (response.data.clientExists) {
        // Show confirmation modal
        setDuplicateClient(true);
      } else {
        // If no existing client with the same name, proceed as usual
        setDuplicateClient(false);
        setOpenAdd(false);
        loadClients();
        setAddData({
          group: id,
          name: "",
          isBusiness: false,
          clientWebsite: "",
          complianceNote: "",
          status: "",
          relatedParties: [],
          ongoingScreening: false,
        });
      }
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };

  const handleChangeAdd = async (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name == "ongoingScreening") {
      const data = await User.getLimit();
      if (data?.data?.subscription?.remainingLimit == 0) {
        setErrorModal(true);
        setAddData((prev) => ({ ...prev, [name]: false, screeningFlag: false }));
      }else{
        setAddData((prev) => ({ ...prev, [name]: value, screeningFlag: value }));
      }
      console.log(data?.data);
    } else {
      setAddData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const [openAdd, setOpenAdd] = useState(false);
  useEffect(() => {
    if (id) {
      const getSetClient = async () => {
        const { data } = await ClientService.getClientGroups();
        setClientData(data.find((e) => e._id === id));
      };
      getSetClient();
    }
  }, [id]);

  
  const fetchDeletedClients = useCallback(async () => {
    setLoadings(true);
    try {
      const response = await Client.getClientsDeleted({});
      setDeletedClients(response.data);
    } catch (error) {
      console.error("Error fetching deleted clients:", error);
      message.error("Failed to fetch deleted clients");
    } finally {
      setLoadings(false);
    }
  }, []);

  useEffect(() => {
    if (displayDeleted) {
      fetchDeletedClients();
    } else {
      setDeletedClients([]);
    }
  }, [displayDeleted, fetchDeletedClients]);

  return (
    <div>
      <Button
        className="mb-4"
        type="primary"
        onClick={() => navigate(`/app/clients`)}
      >
        <div className="flex items-center gap-2">
          <AiOutlineDoubleLeft />
          <div>Back to Client Groups</div>
        </div>
      </Button>

      <h3 className="capitalize">{clientData.name}</h3>
      {canEdit && (
        <Descriptions
          layout="horizontal"
          colon={false}
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          className="mt-10"
        >
          <Descriptions.Item
            label="Name"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={{
                onChange: (e) => {
                  setClientData((prev) => ({ ...prev, name: e }));
                  if (e === clientData.name) return;
                  EditClientGroup({ ...clientData, name: e });
                },
              }}
            >
              {clientData.name}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item
            label="Group Description"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={{
                onChange: (e) => {
                  setClientData((prev) => ({ ...prev, businessTagLine: e }));
                  if (e === clientData.businessTagLine) return;
                  EditClientGroup({ ...clientData, businessTagLine: e });
                },
              }}
            >
              {clientData.businessTagLine}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      )}

      <h4 className="!mt-20 capitalize">{displayDeleted && "Deleted "}Clients</h4>
      <div className="flex flex-col w-full">
        <Table
          className="w-full max-w-2xl"
          // dataSource={}
          dataSource={displayDeleted?deletedClients:Clients.filter((c) => displayArchive || !c.archived)}
          columns={columns}
          loading={loading}
          footer={() =>
            canEdit ? (
              <div className="flex gap-3 items-center justify-between">
                <Button type="primary" onClick={() => setOpenAdd(true)}>
                  Add Client
                </Button>
                <div className="flex items-center gap-3">
                  <span>Display Deleted</span>
                  <Switch
                    checked={displayDeleted}
                    onChange={() => setDisplayDeleted(!displayDeleted)}
                  />
                </div>
              </div>
            ) : null
          }
        />

        {canEdit && (
          <div>
            <Button type="primary">
              <label htmlFor="bulk-upload">Bulk Add</label>
              <Input
                className="hidden"
                type="file"
                id="bulk-upload"
                onChange={(e) =>
                  handleXLSXTOJSON(
                    { sheet: e.target.files[0] },
                    async (json) => {
                      json.shift();

                      await Client.createClientBulk({
                        clients: json,
                        groupId: id,
                      });
                      loadClients();
                      setOpenAdd(false);
                    }
                  )
                }
              />
            </Button>
            <a className="mx-3" href={SampeBulkAddClients} download={true}>
              Sample Excel Sheet
            </a>
          </div>
        )}

        <Modal
          open={openAdd}
          onCancel={() => setOpenAdd(false)}
          title="Add Client"
          onOk={() => Add(true)}
          loading={loading}
          footer={[
            <Button onClick={() => setOpenAdd(false)} danger>
              Cancel
            </Button>,
            <Button type="primary" onClick={() => Add(true)} loading={loading}>
              Add Client
            </Button>,
          ]}
        >
          {fields.map((e, i) => (
            <div className="mb-4" key={i}>
              <label>{e.placeholder}</label>
              {typeof AddData[e.name] === "boolean" ? (
                <>
                  <Switch
                    onChange={(x) =>
                      handleChangeAdd({ target: { value: x, name: e.name } })
                    }
                    name={`${e.name}`}
                    placeholder={e.placeholder}
                    checkedChildren={
                      e.name === "isBusiness" ? "Entity" : undefined
                    }
                    unCheckedChildren={
                      e.name === "isBusiness" ? "Individual" : undefined
                    }
                  />
                  {e.name === "ongoingScreening" &&
                    AddData.ongoingScreening === true && (
                      <label>Are you sure you want to edit the client?</label>
                    )}
                </>
              ) : (
                <Input
                  value={AddData[e.name] || ""}
                  onPressEnter={() => Add(true)}
                  onChange={handleChangeAdd}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  required
                />
              )}
            </div>
          ))}
        </Modal>
        <Modal
          title="Confirmation"
          visible={visible}
          onOk={handleYes}
          onCancel={handleNo}
          okText="Yes"
          cancelText="No"
        >
          <p>Are you sure you want to start screening ?</p>
        </Modal>
        <Modal
          title="Adding duplicate name client"
          visible={duplicateClient}
          onOk={() => Add(false)}
          onCancel={() => setDuplicateClient(false)}
          okText="Yes"
          cancelText="No"
        >
          <p>Client with the same name is already exist, are you sure you want to continue ?</p>
        </Modal>
        <Modal
          title="Confirmation"
          visible={errorModal}
          onOk={() => setErrorModal(false)}
          onCancel={() => setErrorModal(false)}
          okText="Ok"
          cancelText="No"
        >
          <p>
            You have reached your maximum screening limit. To add more clients
            to the screening, increase the license limit by purchase.
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => navigate("/app/plan")}
            >
              {" "}
              go to plan page{" "}
            </span>
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default EditGroupPage;
