import { Button, Input, Select, Skeleton, Table, Tag, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Admin from "../../../service/Admin";

const { Search } = Input;
const { Option } = Select;

function AdminUsers() {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);

  const [filters, setFilters] = useState({
    role: "",
    accountActivated: "",
    name: "",
    page: 1,
  });

  const getData = useCallback(async () => {
    setLoading(true);
    const response = await Admin.listUsers({
      role: filters.role,
      accountActivated: filters.accountActivated,
      name: filters.name,
      limit: 10,
      page: filters.page,
    });

    setUsers(response.data);
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSearch = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, name: value, page: 1 }));
  };

  const handleRoleFilter = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, role: value, page: 1 }));
  };

  const handleAccountActivatedFilter = (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      accountActivated: value,
      page: 1,
    }));
  };

  const handleShowModal = (userId, userName) => {
    setUserToUpdate({ userId, userName });
    setIsModalVisible(true);
  };

  const handleUpdateUser = async () => {
    await Admin.updateUserDetails({ userId: userToUpdate.userId, update: { accountActivated: false } });
    setIsModalVisible(false);
    setUserToUpdate(null);
    getData();
  };

  const baseColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Account Activated",
      dataIndex: "accountActivated",
      key: "accountActivated",
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (__, data) => (
        <Link to={`/app/userdetails/${data?._id}`}>
          <Button type="link">User Details</Button>
        </Link>
      ),
    },
  ];

  if (filters.accountActivated !== false) {
    baseColumns.push({
      title: "Delete User",
      dataIndex: "accountActivated",
      key: "accountActivated",
      render: (data, record) => (
        <Tag color="red" onClick={() => handleShowModal(record._id, record.name)} style={{ cursor: "pointer" }}>
          Delete
        </Tag>
      ),
    });
  }

  if (!users) {
    return <Skeleton active />;
  }
  return (
    <div className="p-4">
      <h3>User Management</h3>
      <div className="flex items-center space-x-4 mb-4 my-10">
        <Search
          placeholder="Search by name"
          onSearch={handleSearch}
          enterButton
        />
        <Select
          placeholder="Filter by role"
          style={{ width: 200 }}
          onChange={handleRoleFilter}
        >
          <Option value="">All Roles</Option>
          <Option value="client">Client</Option>
          <Option value="admin">Admin</Option>
        </Select>
        <Select
          placeholder="Filter by account activation"
          style={{ width: 200 }}
          onChange={handleAccountActivatedFilter}
        >
          {/* <Option value="">All</Option> */}
          <Option value={true}>Activated</Option>
          <Option value={false}>Deleted</Option>
        </Select>
      </div>
      <Table
        dataSource={users?.data}
        columns={baseColumns}
        loading={loading}
        pagination={{
          total: users.total,
          pageSize: 10,
          showSizeChanger: false,
          onChange: (pe) => {
            setFilters((e) => ({ ...e, page: pe }));
          },
        }}
      />
      <Modal
        title={`Are you sure you want to delete ${userToUpdate?.userName}?`}
        visible={isModalVisible}
        onOk={handleUpdateUser}
        onCancel={() => setIsModalVisible(false)}
        okText="Delete"
        cancelText="Cancel"
      >
      </Modal>
    </div>
  );
}

export default AdminUsers;
