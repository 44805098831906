import {
  Alert,
  Button,
  Dropdown,
  Input,
  Modal,
  Skeleton,
  Space,
  Table,
  Tag,
} from "antd";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { BsPersonFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import Statistics from "../Statistics";

import Cookies from "js-cookie";
import moment from "moment";
import { AiOutlineTable } from "react-icons/ai";
import { GrNote } from "react-icons/gr";
import ClientService from "../../../service/Client";
import User from "../../../service/User";
import { handleDownloadPdf } from "../../../utils/handleDownloadPdf";
import DynamicDescription from "../SearchEntity/DynamicDescription";

const getGreeting = () => {
  const currentTime = parseInt(new Date().getHours());

  if (currentTime >= 0 && currentTime < 5) return "Good night";
  if (currentTime >= 5 && currentTime < 11) return "Good morning";
  if (currentTime >= 11 && currentTime < 18) return "Good afternoon";
  if (currentTime >= 18 && currentTime < 21) return "Good evening";
  if (currentTime >= 21 && currentTime < 24) return "Good night";
};

const DashboardHome = () => {
  const user = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const [clients, setclients] = useState(null);
  const [unresolvedHitNum, setUnresolvedHitNum] = useState(null);
  const [unresolvedHits, setUnresolvedHits] = useState([]);
  const [propertiesModal, setPropertiesModal] = useState(null);
  const [oldResult, setOldResult] = useState(null);
  const [currentScreenings, setCurrentScreenings] = useState(0);
  const [screeningLimit, setScreeningLimit] = useState(0);
  const [selectedScreening, setSelectedScreening] = useState([]);
  const [note, setNote] = useState(null);

  const canEdit = (() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken);
    return decoded.hasFullClientAccess;
  })();

  const getDashboardDetails = async () => {
    const data = await User.dashboardDetails();

    setclients(data?.data?.numberClients);
    setUnresolvedHitNum(data?.data?.unresolvedHitNum);
    setUnresolvedHits(data?.data?.unresolvedHits);
  };

  const getLimit = async () => {
    const data = await User.getLimit();

    setCurrentScreenings(data?.data?.currentScreenings);
    setScreeningLimit(data?.data?.limit);
  };

  useEffect(() => {
    getDashboardDetails();
    getLimit();
  }, []);
  if (clients === null) {
    return <Skeleton active />;
  }

  const screeningColumn = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      render: (a, record) => <div className="whitespace-nowrap">{a}</div>,
    },
    {
      title: "Hit Name",
      dataIndex: "caption",
      render: (a, record) => <div className="whitespace-nowrap">{a}</div>,
    },
    {
      title: "Details",
      dataIndex: "properties",
      key: "properties",
      render: (a, record) => (
        <>
          <Button
            onClick={() => {
              setPropertiesModal(a);
              if (record.oldResults && record.oldResults.length > 0)
                setOldResult(record.oldResults[record.oldResults.length - 1]);
            }}
            type="link"
          >
            Details
          </Button>
        </>
      ),
    },
    {
      title: "Hit Date",
      dataIndex: "hitDate",
      render: (a) => (
        <div className="whitespace-nowrap">
          {moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    {
      title: "Match Status",
      dataIndex: "matchStatus",
      render: (a) => (
        <Tag
          color={
            a === "Match" ? "green" : a === "False Positive" ? "red" : null
          }
        >
          {a}
        </Tag>
      ),
    },
    {
      display: canEdit,
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Space>
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: "download",
                  label: "Download Report",
                  onClick: () => handleDownloadPdf(record),
                },
                {
                  key: "match",
                  label: "Mark as Match",
                  onClick: async () => {
                    console.log("mark as match clicked..");
                    await ClientService.changeScreeningResultStatus({
                      clientId: record.clientId,
                      screeningId: [record.screeningId],
                      entityId: [record.id],
                      matchStatus: "Match",
                    });
                    await getDashboardDetails();
                  },
                  style: { color: "green" },
                },
                {
                  key: "false-positive",
                  label: "Mark as False Positive",
                  onClick: async () => {
                    console.log("mark as match clicked..");
                    await ClientService.changeScreeningResultStatus({
                      clientId: record.clientId,
                      screeningId: [record.screeningId],
                      entityId: [record.id],
                      matchStatus: "False Positive",
                    });
                    await getDashboardDetails();
                  },
                  danger: true,
                },
              ],
            }}
          >
            Actions
          </Dropdown.Button>
        </Space>
      ),
    },
    {
      display: canEdit,
      title: "Notes",
      dataIndex: "userNotes",
      render: (a, record) => (
        <div
          className="whitespace-nowrap cursor-pointer"
          onClick={() =>
            setNote({
              caption: record.caption,
              userNotes: record.userNotes,
              screeningId: record.screeningId,
              entityId: record.id,
              clientId: record.clientId,
            })
          }
        >
          <GrNote />
        </div>
      ),
    },
  ].filter((e) => e.display !== false);

  const handleCloseNote = async () => {
    await ClientService.changeScreeningResultStatus({
      clientId: note.clientId,
      screeningId: [note.screeningId],
      entityId: [note.entityId],
      userNotes: note.userNotes,
    });
    await getDashboardDetails();
    setNote(null);
  };

  return (
    <>
      <div className="page-title-group">
        <h4>
          👋🏽 {getGreeting()} {user?.name ?? ""}!
        </h4>
        <p className="text-color-grey mb-4">Welcome back!</p>

        <div className="">
          <Statistics
            statistics={[
              {
                id: 1,
                title: "Clients", // (status weiß)
                value: clients,
                icon: <BsPersonFill />,
              },
              {
                id: 2,
                title: "Unresolved Hits", // (status weiß)
                value: unresolvedHitNum,
                icon: <AiOutlineTable />,
              },
            ]}
            hideFilter
          />
        </div>

        {/* {currentScreenings < screeningLimit ? ( */}
          <div className="mt-2">
            <h4>Unresolved Hits</h4>

            <Table
              className="w-full max-w-2xl"
              dataSource={unresolvedHits}
              rowKey="id"
              columns={screeningColumn}
              loading={loading}
              pagination={true}
              rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedScreening(selectedRows);
                },
              }}
              footer={() =>
                selectedScreening && canEdit
                  ? [
                      <Dropdown.Button
                        type="primary"
                        menu={{
                          items: [
                            {
                              key: "match",
                              label: "Mark as Match",
                              onClick: async () => {
                                await ClientService.changeScreeningResultStatus(
                                  {
                                    clientId: selectedScreening?.[0]?.clientId,
                                    screeningId: selectedScreening.map(
                                      (record) => record.screeningId
                                    ),
                                    entityId: selectedScreening.map(
                                      (record) => record.id
                                    ),
                                    matchStatus: "Match",
                                  }
                                );
                                await getDashboardDetails();
                              },
                              style: { color: "green" },
                            },
                            {
                              key: "false-positive",
                              label: "Mark as False Positive",
                              onClick: async () => {
                                await ClientService.changeScreeningResultStatus(
                                  {
                                    clientId: selectedScreening?.[0]?.clientId,
                                    screeningId: selectedScreening.map(
                                      (record) => record.screeningId
                                    ),
                                    entityId: selectedScreening.map(
                                      (record) => record.id
                                    ),
                                    matchStatus: "False Positive",
                                  }
                                );
                                await getDashboardDetails();
                              },
                              danger: true,
                            },
                          ],
                        }}
                      >
                        Bulk Actions
                      </Dropdown.Button>,
                    ]
                  : []
              }
            />
          </div>
        {/* ) : (
          <Alert
            className="mt-2"
            type="warning"
            message="You have used up your screening credits. Either deactivate the ongoing screening on your clients to reduce your credit usage, or increase your limit by buying a license. Please visit Plan page for more details. You can access the Plan page from the dropdown menu on Avatar at the right top section."
          />
        )
        } */}

        <Modal
          open={propertiesModal}
          onCancel={() => setPropertiesModal(null)}
          title="Details"
          footer={[
            <Button onClick={() => setPropertiesModal(null)}>Close</Button>,
          ]}
          destroyOnClose
        >
          {propertiesModal && (
            <DynamicDescription
              data={propertiesModal}
              oldResult={oldResult?.properties}
            />
          )}
        </Modal>
      </div>

      <Modal
        open={note !== null}
        onCancel={handleCloseNote}
        onOk={handleCloseNote}
        title={`Notes on ${note?.caption}`}
        loading={loading}
        footer={[]}
      >
        <Input.TextArea
          rows={4}
          value={note?.userNotes}
          onChange={(e) =>
            setNote((note) => ({ ...note, userNotes: e.target.value }))
          }
        />
      </Modal>
    </>
  );
};

export default DashboardHome;
