import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Copyright from "./Copyright";
import OtherLegal from "./OtherLegal";
import Privacy from "./Privacy";
import Terms from "./Terms";

const Legal = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className="landing-wrapper">
        <div>
          <div className="navbar wf-section">
            <div className="navbar-space" />
            <div
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-out"
              data-easing2="ease-out"
              role="banner"
              className="navbar-inner w-nav"
            >
              <div className="container">
                <div className="navbar-wrapper">
                  <nav
                    role="navigation"
                    className="nav-menu-wrapper w-nav-menu"
                  >
                    <div className="nav-menu-flex">
                      <Link to="/" className="w-nav-brand">
                        <img
                          src="/images/Logo_horizontal.png"
                          loading="lazy"
                          height
                          alt=""
                          width={357}
                          srcSet="/images/Logo_horizontal-p-500.png 500w, images/Logo_horizontal-p-800.png 800w, images/Logo_horizontal-p-1080.png 1080w, images/Logo_horizontal-p-1600.png 1600w, images/Logo_horizontal-p-2000.png 2000w, images/Logo_horizontal-p-2600.png 2600w, images/Logo_horizontal.png 3056w"
                          sizes="(max-width: 767px) 100vw, (max-width: 991px) 203.8715362548828px, (max-width: 1279px) 21vw, 18vw"
                          className="logo"
                        />
                      </Link>
                      <ul role="list" className="nav-menu w-list-unstyled">
                        <li>
                          <Link to="/" className="nav-link">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/legal/privacy"
                            className={`nav-link ${
                              location?.pathname?.includes?.("privacy")
                                ? "selected"
                                : ""
                            }`}
                          >
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/legal/terms"
                            className={`nav-link ${
                              location?.pathname?.includes?.("terms")
                                ? "selected"
                                : ""
                            }`}
                          >
                            Terms of Service
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/legal/copyright"
                            className={`nav-link ${
                              location?.pathname?.includes?.("copyright")
                                ? "selected"
                                : ""
                            }`}
                          >
                            Copyright
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/legal/otherlegal"
                            className={`nav-link ${
                              location?.pathname?.includes?.("otherlegal")
                                ? "selected"
                                : ""
                            }`}
                          >
                            Other
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact" className="nav-link">
                            Contact
                          </Link>
                        </li>
                      </ul>
                      <Link to="/auth/login" style={{ marginRight: 20 }}>
                        <img
                          src="/images/Frame-10.png"
                          loading="lazy"
                          width={45}
                          alt=""
                          className="image-2"
                        />
                      </Link>
                      <div className="navbar-button-wrapper">
                        <Link
                          to="/contact"
                          className="button cc-small w-button"
                        >
                          Say Hello
                        </Link>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div
              data-collapse="medium"
              data-animation="default"
              data-duration={400}
              data-easing="ease-out"
              data-easing2="ease-out"
              data-w-id="577246c2-be80-9a0d-4fe9-018c566815d9"
              role="banner"
              className="navbar-inner-mobile w-nav"
            >
              <div className="container">
                <div className="navbar-wrapper-mobile">
                  <Link
                    to="/"
                    aria-current="page"
                    className="navbar-brand-mobile w-nav-brand w--current"
                  >
                    <img
                      src="/images/Logo_horizontal.png"
                      loading="lazy"
                      height
                      width="119.5"
                      alt="flux"
                      className="logo"
                    />
                  </Link>
                  <div
                    data-w-id="577246c2-be80-9a0d-4fe9-018c566815de"
                    className="menu-button w-nav-button"
                    onClick={() => setMobileMenu((e) => !e)}
                  >
                    <div className="burger">
                      <div className="burger-line cc-top" />
                      <div className="burger-line cc-middle" />
                      <div className="burger-line cc-bottom" />
                    </div>
                  </div>
                </div>
                <nav
                  role="navigation"
                  className="nav-menu-wrapper-mobile w-nav-menu"
                  style={{ display: mobileMenu ? "unset" : "none" }}
                >
                  <div className="nav-menu-flex-mobile">
                    <ul role="list" className="nav-menu w-list-unstyled">
                      <li>
                        <Link
                          to="/"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/legal/privacy"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/legal/terms"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Terms of Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/legal/copyright"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Copyright
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/legal/otherlegal"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Other Legal Information
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="navbar-shadow" />
            <div className="navbar-space" />
          </div>

          <div className="container">
            <Routes>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/copyright" element={<Copyright />} />
              <Route path="/otherlegal" element={<OtherLegal />} />
            </Routes>
          </div>

          <div className="footer background-dark">
            <div className="container">
              <div className="footer-cta">
                <div className="join">
                  <h2 className="display-3 text-white">
                    Start your AML journey today!
                  </h2>
                </div>
                <div className="div-block">
                  <Link to="/contact" className="button footerbutton w-button">
                    Say Hello
                  </Link>
                </div>
              </div>
              <div className="divider" />
              <div className="footer-grid">
                <div>
                  {/* <img
                    src="/images/Logo_stacked_white.png"
                    loading="lazy"
                    height
                    alt=""
                    width="119.5"
                    srcSet="/images/Logo_stacked_white-p-500.png 500w, images/Logo_stacked_white-p-800.png 800w, images/Logo_stacked_white-p-1080.png 1080w, images/Logo_stacked_white-p-1600.png 1600w, images/Logo_stacked_white.png 1877w"
                    sizes="(max-width: 479px) 59vw, 119.49653625488281px"
                    className="logo margin-bottom-20"
                  /> */}
                   <img
                  src="/images/Logo_horizontal_white.png"
                  loading="lazy"
                  height
                  alt=""
                  width="220"
                  sizes="(max-width: 479px) 59vw, 119.49653625488281px"
                  className="logo margin-bottom-20"
                />
                  <div className="margin-bottom-20">
                    © {new Date().getFullYear()} Synrgy Solutions.
                  </div>
                  <div className="social-links-wrapper">
                    <ul role="list" className="social-links w-list-unstyled">
                      <li className="social-link-item">
                        <a
                          href="https://www.linkedin.com/company/synrgyaml/"
                          target="_blank"
                          className="social-link w-inline-block"
                        >
                          <div className="unicon"></div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbcf-cd8efba7">
                  <div className="address margin-bottom-20">Get In Touch</div>
                  <a
                    href="mailto:info@synrgy.solutions"
                    className="link-hover link-white"
                  >
                    <strong>info@synrgy.solutions</strong>
                  </a>
                </div>
                <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbd8-cd8efba7">
                  <ul role="list" className="unordered-list margin-bottom-none">
                    <li>
                      <Link to="/" className="link-hover link-white">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/legal/privacy"
                        className="link-hover link-white"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/legal/terms" className="link-hover link-white">
                        Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/legal/copyright"
                        className="link-hover link-white"
                      >
                        Copyright
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/legal/otherlegal"
                        className="link-hover link-white"
                      >
                        Other Legal Information
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" className="link-hover link-white">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/auth/login" className="link-hover link-white">
                        Platform
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Legal;
