import {
  Button,
  Descriptions,
  Divider,
  Form,
  Input,
  Skeleton,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { selectLoading } from "../../../redux/auth/selectors";
import Admin from "../../../service/Admin";
import User from "../../../service/User";
import { notificationDetail } from "./data";
import moment from "moment";
const Index = () => {
  const loading = useSelector(selectLoading);
  const { userId } = useParams();
  const [currentLimit, setCurrentLimit] = useState(0);
  const [limit, setLimit] = useState(0)
  const [user, setUser] = useState({
    blocked: false,
    subscription: {
      autoRenewal: null,
      currentLimit: null,
      remainingLimit: null,
      paymentStatus: null,
      // nextRenewalDate:null,
    },
    name: null,
    isBusiness: null,
    email: null,
    employeeStrength: null,
    contactNumber: null,
    role: null,
    subUsers: null,
    clients: null,
    notifications: null,
    accountActivated: null,
    emailConfirmed: null,
    address: { street: "", nr: "", postcode: "", city: "" },
    lastNotificationAboutSubscriptionPlan: null,
    limit: 0,
    vatNumber: null,
  });
  useEffect(() => {
    Admin.userDetails({ userId }).then(({ data }) => {
      setUser(data);
    });
    User.getLimit().then(({ data }) => setCurrentLimit(data.limit));
  }, [userId]);
  if (!user.email) {
    return <Skeleton active />;
  }

  const handleChange = (e, complex) => {
    if (!complex) {
      const name = e.target.name;
      const value = e.target.value;
      if (name === "limit") {
        setLimit(value);
      }
      setUser((prev) => ({ ...prev, [name]: value }));
    } else {
      setUser((prev) => ({ ...prev, ...complex }));
    }
  };
  const handleSave = async () => {
    const update = { ...user };
    delete update._id;
    delete update.__v;
    delete update.subUsers;
    delete update.clients;
    await Admin.updateUserDetails({ userId, update, newLimit:limit });
    Admin.userDetails({ userId }).then(({ data }) => {
      setUser(data);
    });
    setLimit(0);
  };
  const handleNotifiySubscription = async () => {
    await Admin.triggerSubscriptionReminderInOneYear({ userId });
  };
  return (
    <>
      <h3>User Profile</h3>
      <div onSubmit={() => {}} className="space-y-4">
        <div className="flex space-x-4">
          <div className="w-full">
            <div>
              <label>{"Email"}</label>
              {user.email}
            </div>
            <div className="mb-4">
              <label>{"Contact Phone"}</label>
              {user.contactNumber}
            </div>
            <div>
              <label>{user?.isBusiness ? "Company name" : "Full name"}</label>
              <Input name="name" value={user?.name} onChange={handleChange} />
            </div>
            <div className="mt-4 mb-4">
              <Switch
                onChange={(e) =>
                  setUser((prev) => ({ ...prev, isBusiness: e }))
                }
                checked={user?.isBusiness}
                checkedChildren="Business"
                unCheckedChildren="Individual"
              />
            </div>

            {user.isBusiness && (
              <div>
                <label>Company Headcount</label>
                <input
                  type="number"
                  name="employeeStrength"
                  onChange={handleChange}
                  value={user.employeeStrength}
                  className="field nonsvg w-input"
                  maxLength={256}
                  placeholder={`Enter company headcount`}
                />
              </div>
            )}

            <div>
              <label>VAT</label>
              <Input
                name="vatNumber"
                onChange={handleChange}
                value={user?.vatNumber}
              />
            </div>

            <div>
              <label>Role</label>
              {user.role === "team_member" ? (
                "Team Member"
              ) : (
                <Select
                  value={{ value: user.role, label: user.role }}
                  onChange={(e) =>
                    setUser((prev) => ({ ...prev, role: e.value }))
                  }
                  options={["client", "admin"].map((e) => ({
                    value: e,
                    label: e,
                  }))}
                />
              )}
            </div>

            <div className="flex w-full">
              <div className="w-full">
                <label>Street</label>
                <Input
                  value={user?.address?.street}
                  onChange={(e) =>
                    handleChange(e, {
                      address: { ...user.address, street: e.target.value },
                    })
                  }
                />
              </div>
              <div className="w-1/4">
                <label>No.</label>
                <Input
                  value={user?.address?.nr}
                  onChange={(e) =>
                    handleChange(e, {
                      address: { ...user.address, nr: e.target.value },
                    })
                  }
                />
              </div>
            </div>
            <div className="flex w-full">
              <div className="w-1/3">
                <label>Post Code</label>
                <Input
                  value={user?.address?.postcode}
                  onChange={(e) =>
                    handleChange(e, {
                      address: { ...user.address, postcode: e.target.value },
                    })
                  }
                />
              </div>
              <div className="w-full">
                <label>City</label>
                <Input
                  value={user?.address?.city}
                  onChange={(e) =>
                    handleChange(e, {
                      address: { ...user.address, city: e.target.value },
                    })
                  }
                />
              </div>
            </div>

            <div>
              <label htmlFor="phone">Contact Number</label>
              <Input
                id="phone"
                name="contactNumber"
                value={user?.contactNumber}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Divider />
        <h3 className="!my-10 !text-lg">Account Validation</h3>
        {/* <div className="flex gap-3 !my-5">
          <Switch
            onChange={(e) =>
              setUser((prev) => ({ ...prev, accountActivated: e }))
            }
            checked={user?.accountActivated}
          />
          <label>Account Activated</label>
        </div> */}

        <div className="flex gap-3 !my-5">
          <Switch
            onChange={(e) => setUser((prev) => ({ ...prev, blocked: e }))}
            checked={user?.blocked}
          />
          <label>Blocked</label>
          <span className="text-gray-400 select-none">
            {" "}
            Block access to the app until increases subscribes
          </span>
        </div>
      </div>

      <Divider />
      <h3 className="!my-10 !text-lg">Account Plan</h3>
      <div>
        <label>Limit</label>
        <Input name="limit" onChange={handleChange} value={limit} />
      </div>
      <p className="text-gray-400 select-none">
        If you change above limit the stats below wont matter. This is a manual
        override with no expiration date.
      </p>

      <Button
        type="primary"
        loading={loading}
        onClick={handleNotifiySubscription}
      >
        Trigger Subscription Reminder In One Year
      </Button>
      <Descriptions
        layout="vertical"
        colon={false}
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        className="mt-10"
      >
        <Descriptions.Item
          label="License Limit"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text>{user.subscription.currentLimit}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Remaining Limit"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text>{user.subscription.remainingLimit}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="License Expiry Date"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text className="capitalize">
            {moment(user.subscription.nextRenewalDate).format(
              "YYYY-MM-DD HH:mm"
            )}
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <h3 className="!my-10 !text-lg">Account Settings</h3>
      {Object.keys(user?.notifications).map((key) => (
        <div className="flex items-center mb-2" key={key}>
          <div className="flex gap-8">
            <Switch
              className="form-checkbox text-blue-500 focus:outline-none focus:ring-blue-500"
              checked={user?.notifications[key]}
              onChange={(e) =>
                setUser((prev) => {
                  return {
                    ...prev,
                    notifications: {
                      ...prev.notifications,
                      [key]: e,
                    },
                  };
                })
              }
            />
          </div>

          <span className="ml-2">{notificationDetail[key]}</span>
        </div>
      ))}
      <div></div>
      <Button
        type="primary"
        className="mt-4"
        onClick={handleSave}
        loading={loading}
      >
        Save
      </Button>
    </>
  );
};

export default Index;
