import {
  Button,
  Form,
  Input,
  List,
  Popconfirm,
  Space,
  Typography,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import Admin from "../../../service/Admin";
import Public from "../../../service/Public";

const Pricing = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const loadData = useCallback(async () => {
    const data = await Public.getSubscriptionPackages();

    if (data.data.subscriptions)
      setData(
        Object.keys(data.data.subscriptions).map((d) => ({
          key: d,
          title: "",
          price: 0, // having default values
          ...data.data.subscriptions[d],
        }))
      );
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onFinish = useCallback(
    async (values) => {
      const newPricingElement = {
        title: values.title,
        price: parseFloat(values.price),
        limit: parseInt(values.limit),
        key: uuid(),
      };

      setData((data) => {
        const myList = [...data, newPricingElement];
        Admin.updateSubscriptionPackages({ subscriptions: myList });

        return myList;
      });

      form.resetFields();
    },
    [form]
  );

  const renderItem = (item) => (
    <List.Item>
      <div>
        <strong>Title</strong>{" "}
        <Typography.Text
          editable={{
            value: item.title,
            onChange: (e) => {
              setData((list) => {
                const myList = [...list];
                const myItem = { ...item, title: e };
                const itemFromListIdx = myList.findIndex(
                  (l) => l.key === myItem.key
                );
                myList[itemFromListIdx] = myItem;
                Admin.updateSubscriptionPackages({ subscriptions: myList });
                return myList;
              });
            },
          }}
        >
          {item.title}
        </Typography.Text>{" "}
        -<strong>Price / year</strong>{" "}
        <Typography.Text
          editable={{
            value: item.price,
            onChange: (e) => {
              const num = parseFloat(e);
              if (isNaN(num)) return message.error("Not a number");

              setData((list) => {
                const myList = [...list];
                const myItem = { ...item, price: num };
                const itemFromListIdx = myList.findIndex(
                  (l) => l.key === myItem.key
                );
                myList[itemFromListIdx] = myItem;
                Admin.updateSubscriptionPackages({ subscriptions: myList });
                return myList;
              });
            },
          }}
        >
          {item.price}
        </Typography.Text>{" "}
        , -<strong>Screening Limit</strong>{" "}
        <Typography.Text
          editable={{
            value: item.limit,
            onChange: (e) => {
              const num = parseFloat(e);
              if (isNaN(num)) return message.error("Not a number");

              setData((list) => {
                const myList = [...list];
                const myItem = { ...item, limit: num };
                const itemFromListIdx = myList.findIndex(
                  (l) => l.key === myItem.key
                );
                myList[itemFromListIdx] = myItem;
                Admin.updateSubscriptionPackages({ subscriptions: myList });
                return myList;
              });
            },
          }}
        >
          {item.limit}
        </Typography.Text>{" "}
      </div>
      <Space>
        <Popconfirm
          title="Sure?"
          onConfirm={() => {
            setData((list) => {
              const myList = list.filter((l) => l.key !== item.key);
              Admin.updateSubscriptionPackages({ subscriptions: myList });
              return myList;
            });
          }}
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      </Space>
    </List.Item>
  );

  return (
    <div>
      <h4>Pricing</h4>
      <List dataSource={data} renderItem={renderItem} />

      <div className="p-3 backdrop-blur-xl rounded-lg">
        <h5>Add New Pricing</h5>
        <br />
        <Form form={form} onFinish={onFinish} size="small">
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
            rules={[{ required: true, message: "Please enter a price" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Limit"
            name="limit"
            rules={[{ required: true, message: "Please enter a limit" }]}
          >
            <Input type="number" />
          </Form.Item>
          <Space>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default Pricing;
