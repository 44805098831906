import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class Client {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  createClientGroup({ name, businessTagLine }) {
    return this.api.post("/createClientGroup", { name, businessTagLine });
  }

  createClient(data) {
    return this.api.post("/createClient", data);
  }

  createRelatedParty({ clientId, relatedClient }) {
    return this.api.post("/createRelatedParty", { clientId, relatedClient });
  }

  editClientGroup({ clientGroupId, ...updateFields }) {
    return this.api.put("/editClientGroup", { clientGroupId, ...updateFields });
  }

  editClient({ clientId, ongoingScreening, ...updateFields }) {
    return this.api.put("/editClient", { clientId, ongoingScreening, ...updateFields });
  }

  deleteClientGroup({ clientGroupId }) {
    return this.api.delete("/deleteClientGroup", { data: { clientGroupId } });
  }

  archiveClientGroup({ clientGroupId, archived }) {
    return this.api.post("/archiveClientGroup", { clientGroupId, archived });
  }
  archiveClient({ clientId, archived }) {
    return this.api.post("/archiveClient", { clientId, archived });
  }

  deleteClient({ clientId }) {
    return this.api.delete("/deleteClient", { data: { clientId } });
  }

  getClientGroups() {
    return this.api.get("/getClientGroups");
  }

  getClientsByUser(groupId = "") {
    return this.api.get(`/getClientsByUser?groupId=${groupId}`);
  }
  getClient(id) {
    return this.api.get(`/getClient?id=${id}`);
  }

  getRelatedParties({ clientId }) {
    return this.api.get("/getRelatedParties", { params: { clientId } });
  }

  getScreening({ clientId }) {
    return this.api.get("/getScreening", { params: { clientId } });
  }
  changeScreeningResultStatus({
    clientId,
    screeningId,
    entityId,
    matchStatus,
    userNotes,
  }) {
    return this.api.put(`/changeScreeningResultStatus`, {
      clientId,
      screeningId,
      entityId,
      matchStatus,
      userNotes,
    });
  }
  createClientGroupBulk({ groups }) {
    return this.api.post("/createClientGroupBulk", { data: groups });
  }
  createRelatedPartyBulk({ relatedClients, clientId }) {
    return this.api.post("/createRelatedPartyBulk", {
      relatedClients,
      clientId,
    });
  }
  createClientBulk({ clients, groupId }) {
    const clientsArray = JSON.parse(JSON.stringify(clients)).map((e) => {
      return {
        ...e,
        group: groupId,
      };
    });

    return this.api.post("/createClientBulk", { data: clientsArray });
  }
  getClientFlag({clientId}) {
    return this.api.post("/checkScreeningFlag", {data: clientId});
  }
  softDeleteClient({ clientId, revoked }) {
    return this.api.put("/softDeleteClient", { clientId, revoked });
  }
  getClientsDeleted() {
    return this.api.get("/getClientsDeleted");
  }
}

const ClientService = new Client(`${getBackendUrl()}/client`);
export default ClientService;
