import { Button, Dropdown, Skeleton, Table, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { exportToExcel } from "../Clients/clientByUser";
import User from "../../../service/User";
import Report from "../../../service/Report";

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  total: 0, // Initialize total to 0
};
const OnGoingScreeing = () => {
  const [Reports, setReports] = useState(null);
  const loading = useSelector(selectLoading);
  const [priorReportsData, setPriorReportsData] = useState(null);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const fetchReports = async (pagination = DEFAULT_PAGINATION) => {
    const response = await Report.getOngoingScreening({
      limit: pagination.pageSize,
      offset: (pagination.current - 1) * pagination.pageSize,
    });
    setReports(
      response.data["riskReports"].map((t) => ({ ...t, key: t?._id }))
    );
    setPagination((prev) => ({ ...prev, total: response.data.total }));
  };

  useEffect(() => {
    fetchReports(); // Initialize with the first page
  }, []); // Fetch reports whenever pagination changes

  const columns = [
    {
      title: "Group Name",
      dataIndex: "groupName",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
    },
    {
      title: "OnGoingScreening on",
      dataIndex: "screening",
      render: (a) => (a ? "Yes" : "No"),
    },
  ];
  if (!Reports) {
    return <Skeleton active />;
  }
  return (
    <>
      <Table
        className="w-full max-w-2xl"
        dataSource={Reports}
        columns={columns}
        loading={loading}
        footer={() => (
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: "export-excel",
                  label: (
                    <Button
                      onClick={async () => {
                        if (priorReportsData)
                          return exportToExcel(
                            JSON.parse(JSON.stringify(priorReportsData)).map(
                              (e) => {
                                delete e?.clientId;
                                delete e?.clientGroupId;
                                e.screening = e.screening ? "Yes" : "No";
                                return e;
                              }
                            )
                          );

                        message.info(
                          "Please don't leave this page. It is going to take a while after loading until the download is available.",
                          [8]
                        );
                        const reports = [];

                        const response = await Report.getOngoingScreening();
                        reports.push(
                          response.data["riskReports"].map((t) => ({
                            ...t,
                            key: t?._id,
                          }))
                        );

                        const reportsData = reports
                          .flat()
                          .sort((a, b) =>
                            moment(a.periodicReviewDate).isBefore(
                              b.periodicReviewDate
                            )
                          );
                        setPriorReportsData(reportsData);

                        exportToExcel(
                          JSON.parse(JSON.stringify(reportsData)).map((e) => {
                            delete e?.clientId;
                            delete e?.clientGroupId;
                            e.screening = e.screening ? "Yes" : "No";
                            return e;
                          })
                        );
                      }}
                    >
                      Excel
                    </Button>
                  ),
                },
                {
                  key: "export-pdf",
                  label: (
                    <Button
                      onClick={async () => {
                        if (priorReportsData)
                          return handleGeneratePDF({
                            header: "Reports",
                            ContentColumn: columns.filter(
                              (c) => c.dataIndex !== "rating"
                            ),
                            DataSource: priorReportsData.map((e) => ({
                              ...e,
                              screening: e.screening ? "Yes" : "No",
                            })),
                          });

                        message.info(
                          "Please don't leave this page. It is going to take a while after loading until the download is available.",
                          [8]
                        );
                        const reports = [];

                        const response = await Report.getOngoingScreening();
                        reports.push(
                          response.data["riskReports"].map((t) => ({
                            ...t,
                            key: t?._id,
                          }))
                        );

                        const reportsData = reports
                          .flat()
                          .sort((a, b) =>
                            moment(a.periodicReviewDate).isBefore(
                              b.periodicReviewDate
                            )
                          );

                        setPriorReportsData(reportsData);

                        handleGeneratePDF({
                          header: "Reports",
                          ContentColumn: columns.filter(
                            (c) => c.dataIndex !== "rating"
                          ),
                          DataSource: priorReportsData.map((e) => ({
                            ...e,
                            screening: e.screening ? "Yes" : "No",
                          })),
                        });
                      }}
                    >
                      PDF
                    </Button>
                  ),
                },
              ],
            }}
            arrow={{
              pointAtCenter: true,
            }}
          >
            Export
          </Dropdown.Button>
        )}
        pagination={{
          current: pagination.current, // Current page
          pageSize: pagination.pageSize, // Number of items per page
          total: pagination.total, // Total count
          showSizeChanger: true, // Option to change page size
          pageSizeOptions: ["10", "20", "50"], // Available page sizes
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`, // Display total items
          onChange: (page, pageSize) => {
            // Update the current page in the pagination state
            setPagination((prev) => ({ ...prev, current: page, pageSize }));
            fetchReports({ ...pagination, current: page, pageSize });
          },
        }}
      />
    </>
  );
};

export default OnGoingScreeing;
