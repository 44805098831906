import { Button, Modal, Popconfirm, Typography, Upload, message } from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  HighlightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";
import User from "../../../service/User";
import { uploadToCloudinary } from "../ContentManagement/LandingPage";
import { store } from "../../../redux/store";
import { setLoading } from "../../../redux/auth/actions";
import Admin from "../../../service/Admin";
import Public from "../../../service/Public";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AiOutlineDownload } from "react-icons/ai";

const Support = () => {
  const loading = useSelector(selectLoading);
  const [screenshotUpload, setScreenshotUpload] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [link, setLink] = useState(null);

  const handleOpenUploadModal = () => {
    setUploadModalVisible(true);
  };
  const handleCloseUploadModal = () => {
    setUploadModalVisible(false);
  };

  const handleSubmit = useCallback(
    async (e) => {
      if (loading) return;
      e.preventDefault();
      const type = e.target[0].value;
      const messageText = e.target[1].value;
      await User.createSupportTicket({
        type,
        message: `${messageText}  Url: ${link}`,
      });

      e.target[1].value = "";
    },
    [loading]
  );

  const getData = useCallback(async () => {
    try {
      const res = await Public.getScreenShot();
      if (Array.isArray(res.data)) {
        setScreenshotUpload(res.data);
      } else {
        console.error("API response is not an array:", res.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  const handleUploadScreenshot = useCallback(
    async (file) => {
      store.dispatch(setLoading(true));
      try {
        const cloudinaryResponse = await uploadToCloudinary(file);

        const newFile = {
          title: file.name,
          src: cloudinaryResponse.secure_url,
        };

        setLink(cloudinaryResponse.secure_url);
        await Admin.addScreenShot(newFile);
        await getData();

        message.success("Screenshot uploaded");
        handleCloseUploadModal();
      } catch (e) {
      } finally {
        store.dispatch(setLoading(false));
      }
    },
    [getData]
  );

  const handleDeleteScreenShot = async (fileId) => {
    await Admin.deleteScreenShot(fileId);
    await getData();

    message.success("File deleted");
  };

  const handleChangeTitle = async (text, id) => {
    await Admin.updateScreenShot(id, { title: text });
    await getData();
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;

    if (source.index === destination.index) return;

    const newFiles = Array.from(screenshotUpload);
    const [movedFile] = newFiles.splice(source.index, 1);
    newFiles.splice(destination.index, 0, movedFile);

    await Promise.all(
      newFiles.map(async (file, index) => {
        await Admin.updateScreenShot(file._id, { sequence: index });
      })
    );

    await getData();
  };
  return (
    <>
      <form action="#" className="space-y-8" onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Type
          </label>
          <select defaultValue={"Bug Report"}>
            <option value="Bug Report">Bug Report</option>
            <option value="Technical Support">Technical Support</option>
            <option value="Account Issue">Account Issue</option>
            <option value="Feedback and Suggestions">
              Feedback and Suggestions
            </option>
            <option value="General Inquiry">General Inquiry</option>
          </select>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            Your message
          </label>
          <textarea
            id="message"
            rows={6}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder="Let us know how we may help you"
            defaultValue={""}
          />
        </div>
        <Modal
          title="Update ScreenShot"
          open={uploadModalVisible}
          onCancel={handleCloseUploadModal}
          footer={null}
        >
          <Upload.Dragger
            name="file"
            accept="*"
            beforeUpload={(file) => {
              handleUploadScreenshot(file);
              return false;
            }}
            showUploadList={false}
            visible={uploadModalVisible}
            onCancel={handleCloseUploadModal}
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <p className="ant-upload-text">Click or Drag and Drop</p>
          </Upload.Dragger>
        </Modal>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tutorial-list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2"
              >
                {screenshotUpload
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((file, i) => (
                    <Draggable key={file._id} draggableId={file._id} index={i}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="border p-4"
                        >
                          <div key={i} className="border p-4">
                            <div className="mt-2 flex gap-3 items-center">
                              <Typography.Paragraph
                                editable={{
                                  icon: <HighlightOutlined />,
                                  tooltip: "click to edit text",
                                  onChange: (e) =>
                                    handleChangeTitle(e, file._id),
                                  enterIcon: <CheckOutlined />,
                                }}
                              >
                                {file.title}
                              </Typography.Paragraph>
                              <div
                                className="cursor-pointer mb-3"
                                onClick={async () => {
                                  const response = await fetch(file.src);
                                  const blob = await response.blob();

                                  const link = document.createElement("a");
                                  link.href = URL.createObjectURL(blob);
                                  link.download = file.title;

                                  link.click();
                                }}
                              >
                                <AiOutlineDownload />
                              </div>
                            </div>
                            <div className="mt-2 flex justify-between items-center">
                              <Popconfirm
                                title="Are you sure to delete?"
                                onConfirm={() =>
                                  handleDeleteScreenShot(file._id)
                                }
                              >
                                <Button
                                  type="link"
                                  danger
                                  icon={<DeleteOutlined />}
                                />
                              </Popconfirm>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          <Button
            type="primary"
            className="btn-primary mt-4"
            onClick={handleOpenUploadModal}
          >
            Upload ScreenShot
          </Button>
        </div>

        <Button htmlType="submit" type="primary">
          Send Message
        </Button>
      </form>
    </>
  );
};

export default Support;
