import { Button, Skeleton, Switch, message } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import User from "../../../service/User";

const notificationDetail = {
  createClientGroup:
    "Receive notifications when your team creates client group",
  createClient: "Receive notifications when your team creates client",
  editClientGroup: "Receive notifications when your team edits client group",
  editClient: "Receive notifications when your team edits client",
  deleteClientGroup:
    "Receive notifications when your team deletes client group",
  deleteClient: "Receive notifications when your team deletes client",
};

const Settings = () => {
  const [shouldsave, setshouldsave] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const loadSettings = async () => {
    const { data } = await User.getNotificationSettings();
    setNotifications(data?.notifications);
  };
  useEffect(() => {
    loadSettings();
  }, []);
  const Save = async () => {
    const { data } = await User.changeNotificationSetting(notifications);
    if (data) {
      message.success("Settings Changed");
      setshouldsave(false);
    }
  };
  if (!notifications) {
    return <Skeleton active />;
  }
  return (
    <>
      <h3>Settings</h3>

      <div className="mt-4">
        {Object.keys(notifications).map((key) => (
          <div className="flex items-center mb-2" key={key}>
            <div className="flex gap-8">
              <Switch
                className="form-checkbox text-blue-500 focus:outline-none focus:ring-blue-500"
                checked={notifications[key]}
                onChange={(e) =>
                  setNotifications((c) => {
                    const current = { ...c };
                    current[key] = e;
                    setshouldsave(true);
                    return current;
                  })
                }
              />
            </div>

            <span className="ml-2">{notificationDetail[key]}</span>
          </div>
        ))}
        {shouldsave && (
          <div className="my-10 bg-[#fafafa] rounded-md px-1 py-2 ">
            <Button
              type="primary"
              className="flex items-center gap-1"
              onClick={Save}
            >
              <AiOutlineSave />
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default Settings;
