import pdfMake from "pdfmake/build/pdfmake";
import moment from "moment";

export const handleDownloadPdf = async (record) => {
  try {
    console.log("handeleDownload", record);

    const content = [
      { text: "Entity Details:", style: "header" },

      `Name: ${record.caption}`,
      "_____________________________________________________________________________________",
      `Entity Type: ${record.schema}`,
      "_____________________________________________________________________________________",
      `Target: ${record.target ? "Yes" : "No"}`,
      "_____________________________________________________________________________________",
      record.hitDate
        ? `Hit Date: ${moment(record.hitDate).format("YYYY-MM-DD HH:mm")}`
        : ``,
      record.hitDate
        ? "_____________________________________________________________________________________"
        : "",
      `First Seen: ${moment(record.first_seen).format("YYYY-MM-DD HH:mm")}`,
      "_____________________________________________________________________________________",
      `Last Seen: ${moment(record.last_seen).format("YYYY-MM-DD HH:mm")}`,
      "_____________________________________________________________________________________",
      `Last Change: ${moment(record.last_change).format("YYYY-MM-DD HH:mm")}`,
      "_____________________________________________________________________________________",
      `Date of Report: ${moment(new Date().toISOString()).format(
        "YYYY-MM-DD HH:mm"
      )}`,
      "_____________________________________________________________________________________",
    ];
    if (record.properties.lastName)
      content.push(
        ...[
          `last Name: ${record.properties.lastName}`,
          "_____________________________________________________________________________________",
        ]
      );

    if (record.properties.fatherName)
      content.push(
        ...[
          `father Name: ${record.properties.fatherName}`,
          "_____________________________________________________________________________________",
        ]
      );

    if (record.properties.position)
      content.push(
        ...[
          `position: ${record.properties.position}`,
          "_____________________________________________________________________________________",
        ]
      );

    // clientName
    if (record.clientName)
      content.push(
        ...[
          `clientName: ${record.clientName}`,
          "_____________________________________________________________________________________",
        ]
      );

    if (record.properties.gender)
      content.push(
        ...[
          `gender: ${record.properties.gender}`,
          "_____________________________________________________________________________________",
        ]
      );
    if (record.properties.address)
      content.push(
        ...[
          `address: ${record.properties.address}`,
          "_____________________________________________________________________________________",
        ]
      );

    if (record.properties.country)
      content.push(
        ...[
          `country: ${record.properties.country}`,
          "_____________________________________________________________________________________",
        ]
      );
    if (record.matchStatus)
      content.push(
        ...[
          `Match Status: ${record.matchStatus}`,
          "_____________________________________________________________________________________",
        ]
      );
    if (record.resolveDate)
      content.push(
        ...[
          `Resolve Date: ${moment(record.resolveDate).format(
            "YYYY-MM-DD HH:mm"
          )}`,
          "_____________________________________________________________________________________",
        ]
      );
    const docDefinition = {
      content,
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          decoration: "underline",
          margin: [0, 0, 0, 10],
        },
      },
    };

    // Generate the PDF
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    // Download the PDF
    pdfDocGenerator.download("record.pdf");
  } catch (error) {
    console.error("Error on pdf download", error);
  }
};
