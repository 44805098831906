import {
  Alert,
  Button,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";
import User from "../../../service/User";
import NewTeamMember from "./NewTeamMember";

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [accessedClients, setAccessedClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const loading = useSelector(selectLoading);

  const loadTeamMembers = useCallback(async () => {
    const response = await User.listSubUsers({});
    setTeamMembers(response.data.map((t) => ({ ...t, key: t?._id })));
    setSelectedMember((selectedMember) =>
      selectedMember
        ? response.data.find((m) => m?._id === selectedMember?._id) ?? null
        : null
    );
  }, []);
  const loadClients = useCallback(async () => {
    const response = await User.listClients({});
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
  }, []);
  useEffect(() => {
    loadTeamMembers();
    loadClients();
  }, [loadTeamMembers]);

  useEffect(() => {
    setAccessedClients(selectedMember?.accessedClients ?? []);
  }, [selectedMember]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Team Role",
      dataIndex: "teamMemberRole",
      key: "teamMemberRole",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            onConfirm={() => handleDelete(text._id)}
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleEdit = (record) => {
    setSelectedMember(record);
    setIsModalVisible(true);
  };

  const handleSave = useCallback(async () => {
    await User.editSubUser({
      subUserId: selectedMember._id,
      ...selectedMember,
      accessedClients,
    });
    setIsModalVisible(false);
    loadTeamMembers();
  }, [accessedClients]);

  const handleDelete = async (id) => {
    await User.deleteSubUser({ subUserId: id });
    setIsModalVisible(false);
    loadTeamMembers();
  };

  const handleActivation = async (checked) => {
    await User.setSubuserActivation({
      subUserId: selectedMember._id,
      accountActivated: checked,
    });
    loadTeamMembers();
  };

  const handleFullClientAccess = async (checked) => {
    await User.setFullClientAccess({
      subUserId: selectedMember._id,
      hasFullClientAccess: checked,
    });
    loadTeamMembers();
  };
  if (loading && !selectedMember) {
    return <Skeleton active />;
  }
  return (
    <>
      <h3 className="my-10">Team</h3>
      <div className="flex flex-col my-10">
        <Table
          className="w-full max-w-2xl"
          dataSource={teamMembers}
          columns={columns}
          loading={loading}
          footer={() => (
            <NewTeamMember
              clients={clients}
              loadTeamMembers={loadTeamMembers}
            />
          )}
        />

        <Modal
          title="Edit Team Member"
          open={isModalVisible}
          onOk={handleSave}
          onCancel={() => setIsModalVisible(false)}
          loading={loading}
          footer={[
            <Button onClick={() => setIsModalVisible(false)} danger>
              Cancel
            </Button>,
            <Button type="primary" onClick={() => handleSave()}>
              Save
            </Button>,
          ]}
        >
          <div className="mb-4">
            <label>Name</label>
            <Input
              placeholder="Name"
              value={selectedMember?.name}
              onChange={(e) =>
                setSelectedMember({ ...selectedMember, name: e.target.value })
              }
              onPressEnter={handleSave}
            />
          </div>
          <div className="mb-4">
            <label>Email</label>
            <Input
              placeholder="Email"
              value={selectedMember?.email}
              onChange={(e) =>
                setSelectedMember({ ...selectedMember, email: e.target.value })
              }
              onPressEnter={handleSave}
            />
          </div>
          <div className="mb-4">
            <label>Role</label>
            <Input
              placeholder="Team Role"
              value={selectedMember?.teamMemberRole}
              onChange={(e) =>
                setSelectedMember({
                  ...selectedMember,
                  teamMemberRole: e.target.value,
                })
              }
              onPressEnter={handleSave}
            />
          </div>
          <div className="mb-4 flex items-center gap-2">
            <label>Account Active</label>

            <Switch
              checked={selectedMember?.accountActivated}
              onChange={handleActivation}
              loading={loading}
            />
          </div>

          <Divider />
          <Typography.Title level={3}>Regulatory Access</Typography.Title>
          <div className="mb-4 flex items-center gap-2">
            <label>Full Client Access</label>

            <Switch
              checked={selectedMember?.hasFullClientAccess}
              onChange={handleFullClientAccess}
              loading={loading}
            />
          </div>

          {selectedMember?.hasFullClientAccess === true && (
            <div className="mb-4 flex items-center gap-2">
              <Alert
                type="info"
                message="You can disable full client access, and select specific clients that this user will be able to access. This option is suitable for external regulatory access. Once full client access is disabled, the person will not be able to perform certain action like creating, deleting and managing client groups and clients. Only clients where access has been provided can be edited."
              />
            </div>
          )}

          {selectedMember?.hasFullClientAccess === false && (
            <>
              <div className="mb-4 flex items-center gap-2">
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Accessed Clients"
                  mode="multiple"
                  value={accessedClients}
                  onChange={(e) => setAccessedClients(e)}
                >
                  {clients.map((client) => (
                    <Select.Option value={client._id}>
                      {client.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </>
          )}
        </Modal>
      </div>
    </>
  );
};

export default Team;
