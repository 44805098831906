import React from 'react';
import { Result } from 'antd';

const SuccessPage = () => {
  return (
    <div>
      <Result
        status="success"
        title="Successfully Purchased !"
        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        // extra={[
        //   <Button type="primary" key="console">
        //     Go Console
        //   </Button>,
        //   <Button key="buy">Buy Again</Button>,
        // ]}
      />
    </div>
  );
};

export default SuccessPage;
