import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class Report {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }
  getScreeningReport() {
    return this.api.get("/getScreeningReport");
  }
  getOngoingScreening() {
    return this.api.get("/getscreening");
  }
}

export default new Report(`${getBackendUrl()}/report`);
