import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Alert, Input, Row, Col } from 'antd';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import "./style.css";
import { formatCVC, formatCreditCardNumber, formatExpirationDate } from '../dashboard/Plan/utils';
import Footer from '../common/Footer';
import Header from '../common/Header';

const Step4 = ({ prevStep, handleBuy, data, confirmation }) => {
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [cardCvv, setCardCvv] = useState('');
    const [cardExp, setCardExp] = useState('');
    const [cardNr, setCardNr] = useState('');
    const [cardName, setCardName] = useState('');
    const [focus, setFocus] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (data) {
            setCardCvv(data.cardCvv || '');
            setCardExp(data.cardExp || '');
            setCardNr(data.cardNr || '');
            setCardName(data.cardName || '');
        }
    }, [data]);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        await handleBuy({
            cardCvv,
            cardExp,
            cardNr,
            cardName,
            acceptTerms
        });
        setIsSubmitting(false);
    };

    const handleInputFocus = ({ target }) => setFocus(target.name);

    const handleInputChange = ({ target }) => {
        if (target.name === 'number') {
            target.value = formatCreditCardNumber(target.value);
            setCardNr(target.value);
        } else if (target.name === 'expiry') {
            target.value = formatExpirationDate(target.value);
            setCardExp(target.value);
        } else if (target.name === 'cvc') {
            target.value = formatCVC(target.value);
            setCardCvv(target.value);
        } else if (target.name === 'name') {
            setCardName(target.value);
        }
    };

    return (
        <div>
            <div className="landing-wrapper">
                <Header />
                <div className="container">
                    <section className="bg-white dark:bg-gray-300 mt-8 mb-8" >
                        <form>
                            <h1 className="font-semibold mb-4 ml-4 text-center">Pay As You Go</h1>
                            <h3 className="font-semibold mb-4 ml-4 ">Payment Details</h3>
                            <Cards cvc={cardCvv} expiry={cardExp} number={cardNr} name={cardName} />
                            <div className="form-group mt-4 ml-4">
                                <Row gutter={16} align="middle">
                                    <Col span={6}>
                                        <label>Name on card:</label>
                                    </Col>
                                    <Col span={5}>
                                        <Input
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={cardName}
                                            pattern="[a-zA-Z- ]+"
                                            required
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group mt-4 ml-4">
                                <Row gutter={16} align="middle">
                                    <Col span={6}>
                                        <label>Card Number:</label>
                                    </Col>
                                    <Col span={5}>
                                        <Input
                                            type="tel"
                                            name="number"
                                            placeholder="Card Number"
                                            pattern="[\d| ]{16,22}"
                                            maxLength="19"
                                            required
                                            value={cardNr}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group mt-4 ml-4">
                                <Row gutter={16} align="middle">
                                    <Col span={6}>
                                        <label>Expiration Date:</label>
                                    </Col>
                                    <Col span={5}>
                                        <Input
                                            type="tel"
                                            name="expiry"
                                            placeholder="Valid Thru"
                                            pattern="\d\d/\d\d"
                                            required
                                            value={cardExp}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-group mt-4 ml-4">
                                <Row gutter={16} align="middle">
                                    <Col span={6}>
                                        <label>CVC:</label>
                                    </Col>
                                    <Col span={5}>
                                        <Input
                                            type="tel"
                                            name="cvc"
                                            placeholder="CVC"
                                            pattern="\d{4}|\d{3}"
                                            required
                                            value={cardCvv}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </form>
                        <Checkbox
                            className="mt-5 ml-4"
                            checked={acceptTerms}
                            onChange={(e) => setAcceptTerms(e.target.checked)}
                        >
                            I confirm that I have read and accept the{" "}
                            <a href="/legal/terms" className="text-blue-500" target="_blank" rel="noopener noreferrer">
                                Terms of Service and Privacy Policy
                            </a>
                        </Checkbox>
                        {confirmation && (
                            <Alert message={confirmation} type="error" className="mt-5" />
                        )}
                        <Row gutter={16} className="mt-5 ml-4">
                            <Col>
                                <Button onClick={prevStep} className="button cc-small w-button button-centered mb-4 ml-4">
                                    Previous
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="button cc-small w-button button-centered"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Processing...' : 'Pay'}
                                </Button>
                            </Col>
                        </Row>
                    </section>
                </div>
                <br />
                <Footer />
            </div>
        </div>
    );
};

export default Step4;
