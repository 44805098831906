import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generateBase64FromImage = (imageSrc) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Set cross-origin attribute to avoid CORS issues

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };

    img.src = imageSrc;
  });
};

const handleGeneratePDF = async ({ docDef, header, ContentColumn, DataSource }) => {
  try {
    // const abc = localStorage.getItem("userData");
    // const abcString = JSON.parse(abc);
    const logoBase64 = await generateBase64FromImage(`${window.location.origin}/images/Logo_horizontal.png`);
    const today = new Date().toLocaleDateString();
    const pdfWidth = 595;
    
    const docDefinition = docDef || {
      content: [
        {
          canvas: [
            {
              type: "rect",
              x: (pdfWidth - 570) / 2, // Center the rectangle horizontally
              y: 40,
              w: 500,
              h: 90, // Adjust height to encapsulate all content
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
        },
        {
          // Content container for logo, user details, and date
          absolutePosition: { x: (pdfWidth - 500) / 2 + 20, y: 120 }, // Adjust position for content
          stack: [
            {
              image: logoBase64,
              width: 150,
              alignment: "left",
              margin: [0, 10, 10, 0], // Margin: [left, top, right, bottom]
              absolutePosition: { x: (pdfWidth - 500) / 2 + 20, y: 105 },
            },
            {
              text: [
                { text: `info@synrgy.solutions\n` },
                { text: `Address: 10 Market Street, Unit 2090,\nCamana Bay, Cayman Islands KY19006\n\n` },
                { text: "Date: ", bold: true },
                { text: `${today}` },
              ],
              alignment: "right",
              margin: [0, -35, 10, 0],
            },
          ],
        },
        {
          canvas: [
            {
              type: "rect",
              x: (pdfWidth - 570) / 2,
              y: 10,
              w: 500,
              h: 30,
              lineWidth: 1,
              lineColor: "#000000",
            },
          ],
        },
        {
          text: header,
          fontSize: 15,
          alignment: "center",
          bold: true,
          style: "heading",
          absolutePosition: { x: (pdfWidth - 500) / 2 + 20, y: 185 }, // Adjust position for heading text
        },
        {
          // Table wrapped inside the rectangle
          absolutePosition: { x: (pdfWidth - 500) / 2 + 5, y: 220 }, // Adjust position for table
          table: {
            widths: Array(ContentColumn.length).fill("*"),
            headerRows: 1,
            body: [
              ContentColumn.map((column) => ({
                text: column.title,
                alignment: "center",
                style: "tableHeader",
              })),
              ...DataSource.map((client) =>
                ContentColumn.map((column) => ({
                  text: client[column.dataIndex] || "",
                  alignment: "center",
                  style: "tableData",
                }))
              ),
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 20,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        tableHeader: {
          fontSize: 12,
          bold: true,
          alignment: "center",
          fillColor: "#a5b8e8",
        },
        tableData: {
          fontSize: 10,
          alignment: "center",
        },
      },
    };

    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.download();
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

export { handleGeneratePDF };
