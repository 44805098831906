import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Pagination, Skeleton, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { setLoading } from "../../../redux/auth/actions";
import { store } from "../../../redux/store";
import Public from "../../../service/Public";
const { Paragraph } = Typography;

const UsefulInfo = () => {
  const [tutorials, setTutorials] = useState([]);
  const [files, setFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const showModal = (video) => {
    setCurrentVideo(video);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getData = useCallback(async () => {
    const res = await Public.getTutorials();
    setTutorials(res.data);
  }, []);
  const getDataFiles = useCallback(async () => {
    const res = await Public.getUsefulFiles();
    setFiles(res.data);
  }, []);
  useEffect(() => {
    getData();
    getDataFiles();
  }, [getData, getDataFiles]);

  // Pagination logic
  const itemsPerPage = 1;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tutorials.slice(indexOfFirstItem, indexOfLastItem);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h3>Useful Information</h3>

      {files.length > 0 && (
        <>
          <Divider />

          <div className="container mx-auto p-4">
            <h3 className="text-3xl mb-4">Files</h3>

            <div className="grid grid-cols-1 gap-4">
              {files.map((file, index) => (
                <div key={index} className="flex items-center justify-between">
                  <div className="flex items-center">
                    <span className="ml-2 ">{file.title}</span>
                  </div>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    size="small"
                    onClick={async () => {
                      store.dispatch(setLoading(true));
                      try {
                        const response = await fetch(file.src);
                        const blob = await response.blob();

                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(blob);
                        link.download = file.title;

                        link.click();
                      } catch (e) {
                      } finally {
                        store.dispatch(setLoading(false));
                      }
                    }}
                  >
                    Download
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {tutorials.length > 0 && (
        <>
          <Divider />

          <div className="container mx-auto p-4">
            <h3 className="text-3xl mb-4">Tutorials</h3>

            <div className="grid grid-cols-1 gap-4">
              {currentItems.map((tutorial, i) => (
                <div key={i} className="border p-4">
                  {tutorial.thumbnail ? (
                    <img
                      src={tutorial.thumbnail}
                      alt=" "
                      className="w-full h-64 object-cover"
                      onClick={() => showModal(tutorial)}
                    />
                  ) : (
                    <div
                      className="flex justify-center items-center h-64 bg-gray-200"
                      onClick={() => showModal(tutorial)}
                    >
                      <Skeleton.Image />
                    </div>
                  )}

                  <div className="mt-2">
                    <Paragraph>{tutorial.title}</Paragraph>
                  </div>
                </div>
              ))}
            </div>

            <Pagination
              className="mt-4"
              current={currentPage}
              total={tutorials.length}
              pageSize={itemsPerPage}
              onChange={handleChangePage}
            />
          </div>

          <Modal
            title={currentVideo?.title ?? ""}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={720}
            footer={[]}
            destroyOnClose
          >
            <video width="100%" controls>
              <source src={currentVideo?.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Modal>
        </>
      )}
    </>
  );
};

export default UsefulInfo;
