import {
  Alert,
  Button,
  Descriptions,
  Dropdown,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { GrNote } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SampeBulkAddRelatedParty from "../../../assets/Samples/SampeBulkAddRelatedParty.xlsx";
import { selectLoading } from "../../../redux/auth/selectors";
import {
  default as Client,
  default as ClientService,
} from "../../../service/Client";
import User from "../../../service/User";
import { handleXLSXTOJSON } from "../../../utils/bulkUpload";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { handleDownloadPdf } from "../../../utils/handleDownloadPdf";
import DynamicDescription from "../SearchEntity/DynamicDescription";
import { exportToExcel } from "./clientByUser";

const ClientGroup = () => {
  const fields = [
    { name: "name", placeholder: "Name" },
    { name: "address", placeholder: "Address" },
    { name: "DOB", placeholder: "Date of Birth" },
    { name: "country", placeholder: "Country" },
    { name: "clientRole", placeholder: "Client Relation Type" },
    { name: "complianceNote", placeholder: "Compliance Note" },
    { name: "IDNumber", placeholder: "ID Number" },
    { name: "IDType", placeholder: "ID Type" },
    { name: "IDExpiration", placeholder: "ID Expiration" },
  ];
  const [Clients, setClients] = useState([]);
  const [screeningResult, setScreeningResult] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [note, setNote] = useState(null);
  const [propertiesModal, setPropertiesModal] = useState(null);
  const [oldResult, setOldResult] = useState(null);
  const [selectedScreening, setSelectedScreening] = useState([]);
  const [visible, setVisible] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const loading = useSelector(selectLoading);
  const { clientId } = useParams();
  const navigate = useNavigate();
  const canEdit = (() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken);
    return decoded.hasFullClientAccess;
  })();

  const handleBulkAction = async (status) => {
    openNotesModal(status, selectedScreening);
  };

  const openNotesModal = (status, records) => {
    const allCaptions = records.map(record => record.caption).join(', ');
    setNote({
      userNotes: "",
      caption: allCaptions,
      record: records,
      resolvedDate: new Date(),
      status: status,
    });
  };
  const handleSaveNote = async () => {
    if (note) {
      const userNotesString = note.userNotes;
      const validStatuses = {
        'Mark as False Positive': 'False Positive',
        'Mark as Match': 'Match',
      };
      const validMatchStatus = validStatuses[note?.status] || note?.status;

      const newData = selectedScreening.map(screening => ({
        clientId: screening.clientId,
        screeningId: screening.screeningId,
        entityId: screening.id,
        matchStatus: validMatchStatus,
        userNotes: userNotesString
      }));

      for (const data of newData) {
        try {
          await ClientService.changeScreeningResultStatus(data);
          console.log(`Updated screening for entityId: ${data.entityId}`);
          setSelectedScreening([])
        } catch (error) {
          console.error(`Failed to update screening for entityId: ${data.entityId}`, error);
        }
      }
    }
    handleCloseNote();
  };

  const loadClients = useCallback(async () => {
    const response = await Client.getRelatedParties({ clientId });
    Client.getClient(clientId).then(({ data }) => {
      setClientData(data);
    });
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
  }, [clientId]);
  const loadScreening = useCallback(async () => {
    const res = await ClientService.getScreening({ clientId });
    if (res.data) setScreeningResult(res.data);
  }, [clientId]);
  useEffect(() => {
    loadClients();
    loadScreening();
  }, [loadClients, loadScreening]);
  const [clientData, setClientData] = useState(null);
  const columns = [
    { title: "Name", key: "name", dataIndex: "name" },
    { title: "Address", key: "address", dataIndex: "address" },
    { title: "Date of Birth", key: "DOB", dataIndex: "DOB" },
    { title: "Country", key: "country", dataIndex: "country" },
    {
      title: "Client Relation Type",
      key: "clientRole",
      dataIndex: "clientRole",
    },
    {
      title: "ID Number",
      key: "IDNumber",
      dataIndex: "IDNumber",
    },
    {
      title: "ID Type",
      key: "IDType",
      dataIndex: "IDType",
    },
    {
      title: "ID Expiration",
      key: "IDExpiration",
      dataIndex: "IDExpiration",
    },
    {
      title: "Compliance Note",
      key: "complianceNote",
      dataIndex: "complianceNote",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          {canEdit && (
            <>
              <Button onClick={() => handleEdit(text)} type="primary">
                Edit
              </Button>
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                onConfirm={() => handleDelete(text)}
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [EditData, setEditData] = useState({
    name: "",
    address: "",
    country: "",
    clientRole: "",
    complianceNote: "",
  });
  const [AddData, setAddData] = useState({
    name: "",
    address: "",
    country: "",
    clientRole: "",
    complianceNote: "",
  });

  const [currentScreenings, setCurrentScreenings] = useState(0);
  const [screeningLimit, setScreeningLimit] = useState(0);
  const [currentRemainingLimit, setCurrentRemainingLimit] = useState(0);
  const [handleCustomScreeningData, setHandleCustomScreeningData] =
    useState(null);

  const getLimit = async () => {
    const data = await User.getLimit();
    setCurrentScreenings(data?.data?.currentScreenings);
    setScreeningLimit(data?.data?.limit);
    setCurrentRemainingLimit(data?.data?.subscription?.remainingLimit);
  };
  useEffect(() => {
    getLimit();
  }, []);

  const handleEdit = (data) => {
    setSelectedMember(data);
    setEditData((prev) => ({ ...prev, ...data, clientId: data._id }));
    setOpen(true);
  };

  const handleDelete = async (data) => {
    await Client.deleteClient({
      clientId: data._id,
    }).then(() => setClients((prev) => prev.filter((t) => t._id !== data._id)));
  };
  const Edit = async () => {
    await Client.editClient({
      ...EditData,
      relatedParties: undefined,
    });
    setOpen(false);
    const response = await Client.getRelatedParties({ clientId });
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
    if (selectedMember)
      setSelectedMember(
        response.data.find((m) => m?._id === selectedMember?._id) ?? null
      );
  };
  const Add = async () => {
    await Client.createRelatedParty({
      clientId,
      relatedClient: { ...AddData },
    });
    setOpenAdd(false);
    const response = await Client.getRelatedParties({ clientId });
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
    if (selectedMember)
      setSelectedMember(
        response.data.find((m) => m?._id === selectedMember?._id) ?? null
      );

    setAddData({
      group: params.groupId,
      name: "",
      address: "",
      country: "",
      clientRole: "",
      complianceNote: "",
    });
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeAdd = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddData((prev) => ({ ...prev, [name]: value }));
  };

  const handleYes = async () => {
    await Client.editClient(handleCustomScreeningData);
    setVisible(false);
  };

  const handleNo = () => {
    setClientData((prev) => {
      return { ...prev, ongoingScreening: !clientData.ongoingScreening };
    });
    setVisible(false);
  };

  const handleScreeningChange = (e) => {
    setClientData((prev) => {
      if (e === prev.ongoingScreening) return prev;
      else {
        EditClient({ ...prev, ongoingScreening: e, screeningCustomFlag: true });
        return { ...prev, ongoingScreening: e, screeningFlag: e };
      }
    });
  };

  const EditClient = async (data) => {
    if (!canEdit) return message.error("You have read only access");
    if (data.screeningCustomFlag) {
      const flagResult = await Client.getClientFlag({
        clientId: clientId,
      });
      const resultFlag = flagResult.data.result;
      const limitFlag = flagResult.data.limit;
      if (limitFlag) {
        if (resultFlag) {
          setVisible(true);
          setHandleCustomScreeningData({
            clientId: clientId,
            ...data,
            relatedParties: undefined,
            resultFlag: resultFlag,
          });
        } else {
          await Client.editClient({
            clientId: clientId,
            ...data,
            relatedParties: undefined,
          });
        }
      } else {
        setErrorModal(true);
      }
    } else {
      await Client.editClient({
        clientId: clientId,
        ...data,
        relatedParties: undefined,
      });
    }
  };
  const [openAdd, setOpenAdd] = useState(false);

  const screeningColumn = [
    {
      title: "Name",
      dataIndex: "caption",
      render: (a, record) => <div className="whitespace-nowrap">{a}</div>,
    },
    {
      title: "Details",
      dataIndex: "properties",
      key: "properties",
      render: (a, record) => (
        <>
          <Button
            onClick={() => {
              setPropertiesModal(a);
              if (record.oldResults && record.oldResults.length > 0)
                setOldResult(record.oldResults[record.oldResults.length - 1]);
            }}
            type="link"
          >
            Details
          </Button>
        </>
      ),
    },
    {
      title: "Hit Date",
      dataIndex: "hitDate",
      render: (a) => (
        <div className="whitespace-nowrap">
          {moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    {
      title: "Match Status",
      dataIndex: "matchStatus",
      render: (a) => (
        <Tag
          color={
            a === "Match" ? "green" : a === "False Positive" ? "red" : null
          }
        >
          {a}
        </Tag>
      ),
    },
    {
      title: "Resolve Date",
      dataIndex: "resolveDate",
      render: (a) => (
        <div className="whitespace-nowrap">
          {!a ? "" : moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    {
      display: canEdit,
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Space>
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: "download",
                  label: "Download Report",
                  onClick: () => handleDownloadPdf(record),
                },
                {
                  key: "match",
                  label: "Mark as Match",
                  onClick: async () => {
                    await ClientService.changeScreeningResultStatus({
                      clientId,
                      screeningId: [record.screeningId],
                      entityId: [record.id],
                      matchStatus: "Match",
                    });
                    await loadScreening();
                  },
                  style: { color: "green" },
                },
                {
                  key: "false-positive",
                  label: "Mark as False Positive",
                  onClick: async () => {
                    await ClientService.changeScreeningResultStatus({
                      clientId,
                      screeningId: [record.screeningId],
                      entityId: [record.id],
                      matchStatus: "False Positive",
                    });
                    await loadScreening();
                  },
                  danger: true,
                },
              ],
            }}
          >
            Actions
          </Dropdown.Button>
        </Space>
      ),
    },
    {
      display: canEdit,
      title: "Notes",
      dataIndex: "userNotes",
      render: (a, record) => (
        <div
          className="whitespace-nowrap cursor-pointer"
          onClick={() =>
            setNote({
              caption: record.caption,
              userNotes: record.userNotes,
              screeningId: record.screeningId,
              entityId: record.id,
            })
          }
        >
          <GrNote />
        </div>
      ),
    },
  ].filter((e) => e.display !== false);
  const DropDownMenu = [
    {
      key: "export-excel",
      label: <Button onClick={() => exportToExcel(Clients)}>Excel</Button>,
    },
    // {
    //   key: "export-pdf",
    //   label: (
    //     <Button
    //       onClick={() =>
    //         handleGeneratePDF({
    //           header: "Related Parties",
    //           ContentColumn: columns.filter((e) => e.title !== "Action"),
    //           DataSource: Clients,
    //         })
    //       }
    //     >
    //       PDF
    //     </Button>
    //   ),
    // },
  ];

  const handleCloseNote = async () => {
    await ClientService.changeScreeningResultStatus({
      clientId,
      screeningId: [note.screeningId],
      entityId: [note.entityId],
      userNotes: note.userNotes,
    });
    await loadScreening();
    setNote(null);
  };

  if (!clientData) {
    return <Skeleton active />;
  }

  return (
    <>
      <div className="flex flex-col  ">
        <Button
          className="mb-4 flex-shrink w-52"
          type="primary"
          onClick={() =>
            navigate(`/app/edit-client-group/${clientData?.group}`)
          }
        >
          <div className="flex items-center gap-2">
            <AiOutlineDoubleLeft />
            <div>Back to Clients</div>
          </div>
        </Button>

        <h3 className="capitalize">{clientData.name}</h3>
        <Descriptions
          layout="horizontal"
          colon={false}
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          bordered
          className="mt-10"
        >
          <Descriptions.Item
            label="Name"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.name) return prev;
                        else {
                          EditClient({ ...prev, name: e });
                          return { ...prev, name: e };
                        }
                      });
                    },
                  }
              }
            >
              {clientData.name}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item
            label="Entity or Individual"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Switch
              checkedChildren="Entity"
              unCheckedChildren="Individual"
              value={clientData.isBusiness}
              checked={clientData.isBusiness}
              onChange={(e) => {
                if (!canEdit) return message.error("You have read only access");
                setClientData((prev) => {
                  if (e === prev.isBusiness) return prev;
                  else {
                    EditClient({ ...prev, isBusiness: e });
                    return { ...prev, isBusiness: e };
                  }
                });
              }}
            />
          </Descriptions.Item>

          <Descriptions.Item
            span={2}
            label="Country"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.country) return prev;
                        else {
                          EditClient({ ...prev, country: e });
                          return { ...prev, country: e };
                        }
                      });
                    },
                  }
              }
            >
              {clientData.country}
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item
            span={2}
            label="Website"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.clientWebsite) return prev;
                        else {
                          EditClient({ ...prev, clientWebsite: e });
                          return { ...prev, clientWebsite: e };
                        }
                      });
                    },
                  }
              }
            >
              {clientData.clientWebsite}
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item
            span={2}
            label="Compliance Note"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.complianceNote) return prev;
                        else {
                          EditClient({ ...prev, complianceNote: e });
                          return { ...prev, complianceNote: e };
                        }
                      });
                    },
                  }
              }
            >
              {clientData.complianceNote}
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item
            label="Status"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.status) return prev;
                        else {
                          EditClient({ ...prev, status: e });
                          return { ...prev, status: e };
                        }
                      });
                    },
                  }
              }
            >
              {clientData.status}
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item
            label="Date of Birth"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Input
              value={clientData.DOB}
              onChange={(e) => {
                if (!e.target.value) return;
                if (!canEdit) return message.error("You have read only access");
                setClientData((prev) => {
                  if (e.target.value === prev.DOB) return prev;
                  else {
                    EditClient({ ...prev, DOB: e.target.value });
                    return { ...prev, DOB: e.target.value };
                  }
                });
              }}
              type="date"
            />
          </Descriptions.Item>

          <Descriptions.Item
            label="Ongoing Screening"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Switch
              checked={clientData.ongoingScreening}
              onChange={handleScreeningChange}
            />
          </Descriptions.Item>
        </Descriptions>

        <h4 className="!my-10">ID Information</h4>
        <Descriptions
          layout="horizontal"
          colon={false}
          column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          bordered
          className="mt-10"
        >
          <Descriptions.Item
            label="ID Number"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.IDNumber) return prev;
                        else {
                          EditClient({ ...prev, IDNumber: e });
                          return { ...prev, IDNumber: e };
                        }
                      });
                    },
                  }
              }
            >
              {clientData.IDNumber}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item
            label="ID Type"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.IDType) return prev;
                        else {
                          EditClient({ ...prev, IDType: e });
                          return { ...prev, IDType: e };
                        }
                      });
                    },
                  }
              }
            >
              {clientData.IDType}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item
            label="ID Expiration"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Input
              value={clientData.IDExpiration}
              onChange={(e) => {
                if (!e.target.value) return;
                if (!canEdit) return message.error("You have read only access");
                setClientData((prev) => {
                  if (e.target.value === prev.IDExpiration) return prev;
                  else {
                    EditClient({ ...prev, IDExpiration: e.target.value });
                    return { ...prev, IDExpiration: e.target.value };
                  }
                });
              }}
              type="date"
            />
          </Descriptions.Item>
        </Descriptions>
        <h4 className="!my-10">Related Parties</h4>
        <Table
          className="w-full max-w-2xl"
          dataSource={Clients}
          columns={columns}
          loading={loading}
          footer={() => (
            <>
              <div className="flex gap-3 items-center">
                {canEdit && (
                  <Button type="primary" onClick={() => setOpenAdd(true)}>
                    Add Client Party
                  </Button>
                )}
                <Dropdown.Button
                  type="primary"
                  menu={{
                    items: DropDownMenu,
                  }}
                  arrow={{
                    pointAtCenter: true,
                  }}
                >
                  Export
                </Dropdown.Button>
              </div>
              {canEdit && (
                <div className="mt-2">
                  <Button type="primary">
                    <label htmlFor="bulk-upload">Bulk Add</label>
                    <Input
                      className="hidden"
                      type="file"
                      id="bulk-upload"
                      onChange={(e) =>
                        handleXLSXTOJSON(
                          { sheet: e.target.files[0] },
                          async (json) => {
                            json.shift();
                            await Client.createRelatedPartyBulk({
                              relatedClients: json,
                              clientId,
                            });
                            const response = await Client.getRelatedParties({
                              clientId,
                            });
                            setClients(
                              response.data.map((t) => ({ ...t, key: t?._id }))
                            );
                          }
                        )
                      }
                    />
                  </Button>
                  <a
                    className="mx-3"
                    href={SampeBulkAddRelatedParty}
                    download={true}
                  >
                    Sample Excel Sheet
                  </a>
                </div>
              )}
            </>
          )}
        />
        <Modal
          open={openAdd}
          onCancel={() => setOpenAdd(false)}
          title="Add Client Related Parties"
          onOk={Add}
          loading={loading}
          destroyOnClose
          footer={[
            <Button onClick={() => setOpenAdd(false)} danger>
              Cancel
            </Button>,
            <Button type="primary" onClick={Add} loading={loading}>
              Add Client Party
            </Button>,
          ]}
        >
          {fields.map((e, i) => (
            <div className="mb-4" key={i}>
              <label className="captalize">{e.placeholder}</label>
              {["IDExpiration", "DOB"].includes(e.name) ? (
                <Input
                  value={AddData[e.name] || ""}
                  onChange={handleChange}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  type="date"
                />
              ) : e.name === "address" ? (
                <Input.TextArea
                  value={AddData[e.name] || ""}
                  onChange={handleChangeAdd}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  required
                />
              ) : (
                <Input
                  value={AddData[e.name] || ""}
                  onPressEnter={Add}
                  onChange={handleChangeAdd}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  required
                />
              )}
            </div>
          ))}
        </Modal>
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          title="Edit Client Group"
          onOk={Edit}
          loading={loading}
          destroyOnClose
          footer={[
            <Button onClick={() => setOpen(false)} danger>
              Cancel
            </Button>,
            <Button type="primary" onClick={Edit} loading={loading}>
              Edit Client Party
            </Button>,
          ]}
        >
          {fields.map((e, i) => (
            <div className="mb-4" key={i}>
              <label className="capitalize">{e.placeholder}</label>
              {["IDExpiration", "DOB"].includes(e.name) ? (
                <Input
                  value={EditData[e.name] || ""}
                  onChange={handleChange}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  type="date"
                />
              ) : e.name === "address" ? (
                <Input.TextArea
                  value={EditData[e.name] || ""}
                  onChange={handleChange}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  required
                />
              ) : (
                <Input
                  value={EditData[e.name] || ""}
                  onPressEnter={Edit}
                  onChange={handleChange}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  required
                />
              )}
            </div>
          ))}
        </Modal>
        <Modal
          open={note !== null}
          onCancel={handleCloseNote}
          onOk={handleCloseNote}
          title={`Notes on ${note?.caption}`}
          loading={loading}
          footer={[]}
        >
          <Input.TextArea
            rows={4}
            value={note?.userNotes}
            onChange={(e) =>
              setNote((note) => ({ ...note, userNotes: e.target.value }))
            }
          />
        </Modal>
        <div>
          <h4 className="!my-10">Screening</h4>
          {/* {currentScreenings < screeningLimit ? ( */}
          <Table
            className="w-full max-w-2xl"
            dataSource={screeningResult}
            rowKey="id"
            columns={screeningColumn}
            loading={loading}
            pagination={true}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedScreening.map(item => item.id),
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedScreening(selectedRows);
              },
            }}
            footer={() =>
              selectedScreening && canEdit
                ? [
                  <Dropdown.Button
                    type="primary"
                    menu={{
                      items: [
                        {
                          key: "match",
                          label: "Mark as Match",
                          onClick: () => handleBulkAction("Mark as Match"),
                          style: { color: "green" },
                        },
                        {
                          key: "false-positive",
                          label: "Mark as False Positive",
                          onClick: () => handleBulkAction("Mark as False Positive"),
                          danger: true,
                        },
                      ],
                    }}
                  >
                    Bulk Actions
                  </Dropdown.Button>,
                ]
                : []
            }
          />
          {/* ) : (
        <Alert
          className="mt-2"
          type="warning"
          message="You have used up your screening credits. Either deactivate the ongoing screening on your clients to reduce your credit usage, or increase your limit by buying a license. Please visit Plan page for more details. You can access the Plan page from the dropdown menu on Avatar at the right top section."
        />
        )} */}
          <Modal
            open={note !== null}
            onCancel={handleCloseNote}
            onOk={handleSaveNote}
            title={`Notes on ${note?.caption}`}
            loading={loading}
            footer={[
              <Button key="close" onClick={handleCloseNote}>Close</Button>,
              <Button key="save" type="primary" onClick={handleSaveNote}>Save</Button>,
            ]}
          >
            <Input.TextArea
              rows={4}
              value={note?.userNotes}
              onChange={(e) =>
                setNote((prevNote) => ({ ...prevNote, userNotes: e.target.value }))
              }
            />
          </Modal>
          <Modal
            open={propertiesModal}
            onCancel={() => setPropertiesModal(null)}
            title="Details"
            footer={[
              <Button onClick={() => setPropertiesModal(null)}>Close</Button>,
            ]}
            destroyOnClose
          >
            {propertiesModal && (
              <DynamicDescription
                data={propertiesModal}
                oldResult={oldResult?.properties}
              />
            )}
          </Modal>
        </div>
      </div>
      <Modal
        title="Confirmation"
        visible={visible}
        onOk={handleYes}
        onCancel={handleNo}
        okText="Yes"
        cancelText="No"
      >
        <p>
          Are you sure you want to start screening ? Your current available
          screening limit is {currentRemainingLimit}{" "}.
        </p>
      </Modal>
      <Modal
        title="Confirmation"
        visible={errorModal}
        onOk={() => setErrorModal(false)}
        onCancel={() => setErrorModal(false)}
        okText="Ok"
        cancelText="No"
      >
        <p>
          You have reached your maximum screening limit. To add more clients to
          the screening, increase the license limit by purchase.
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => navigate("/app/plan")}
          >
            {" "}
            go to plan page{" "}
          </span>
        </p>
      </Modal>
    </>
  );
};

export default ClientGroup;
