import { Tabs } from "antd";
import { useState } from "react";

import ScreeningReport from "./ScreeningReport";
import OnGoingScreeing from "./OnGoingScreening";

const Index = () => {
  const [activeTab, setActiveTab] = useState("1");

  const items = [
    {
      key: "1",
      label: `Screening Report`,
    },
    {
      key: "2",
      label: `On Going Screening Report`,
    },
  ];
  return (
    <div className="flex flex-col">
      <h3> Reports</h3>
      <Tabs
        defaultValue="1"
        items={items}
        onChange={(e) => setActiveTab(e)}
        active={activeTab}
      ></Tabs>

      {activeTab === "1" && <ScreeningReport />}
      {activeTab === "2" && <OnGoingScreeing />}
    </div>
  );
};

export default Index;
