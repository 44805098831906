import {
  Alert,
  Button,
  Divider,
  Input,
  Popconfirm,
  Select,
  Skeleton,
  Switch,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";
import {
  default as Admin,
  default as AdminServices,
} from "../../../service/Admin";
const notificationDetail = {
  createClientGroup: "Receive notifications when a team creates client group",
  createClient: "Receive notifications when a team creates client",
  editClientGroup: "Receive notifications when a team edits client group",
  editClient: "Receive notifications when a team edits client",
  deleteClientGroup: "Receive notifications when a team deletes client group",
  deleteClient: "Receive notifications when a team deletes client",
};

const Notifications = () => {
  const [shouldsave, setshouldsave] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [bulkMailSubject, setBulkMailSubject] = useState("");
  const [bulkMailMessage, setBulkMailMessage] = useState("");
  const [recipientRole, setRecipientRole] = useState("all");

  const loading = useSelector(selectLoading);

  const handleBulk = useCallback(async () => {
    if (loading) return;

    const queries = {
      all: {},
      all_except_admin: { role: { $in: ["client", "team_member"] } },
      client: { role: "client" },
      team_member: { role: "team_member" },
      admin: { role: "admin" },
    };

    await Admin.sendBulkEmail({
      subject: bulkMailSubject,
      message: bulkMailMessage,
      query: queries[recipientRole],
    });
    setBulkMailSubject("");
    setBulkMailMessage("");
  }, [bulkMailSubject, bulkMailMessage, loading, recipientRole]);

  const loadSettings = async () => {
    const { data } = await AdminServices.getNotificationConfig();
    setNotifications(data);
    console.log(data);
  };
  useEffect(() => {
    loadSettings();
  }, []);
  const Save = async () => {
    await AdminServices.updateNotifications({ notifications });
    setshouldsave(false);
  };
  if (!notifications) {
    return <Skeleton active />;
  }
  return (
    <>
      <h3>Settings</h3>

      <div className="mt-4">
        {Object.keys(notifications).map((key) => (
          <div className="flex items-center mb-2" key={key}>
            <div className="flex gap-8">
              <Switch
                className="form-checkbox text-blue-500 focus:outline-none focus:ring-blue-500"
                checked={notifications[key]}
                onChange={(e) =>
                  setNotifications((c) => {
                    const current = { ...c };
                    current[key] = e;
                    setshouldsave(true);
                    return current;
                  })
                }
              />
            </div>

            <span className="ml-2">{notificationDetail[key]}</span>
          </div>
        ))}
        {shouldsave && (
          <div className=" my-10 bg-[#fafafa] py-2 ">
            <Button
              type="primary"
              className="flex items-center gap-1"
              onClick={Save}
            >
              <AiOutlineSave />
              Save
            </Button>
          </div>
        )}
      </div>

      <Divider />

      <div>
        <h3>Send Bulk Mail</h3>
        <Alert
          type="warning"
          message="This will send a bulk email to all users in the platform"
        />
        <br />
        <label>Recipient User Role</label>
        <Select
          style={{ minWidth: 200 }}
          value={recipientRole}
          onChange={(e) => setRecipientRole(e)}
          options={[
            { value: "all", label: "All" },
            { value: "all_except_admin", label: "All Except Admin" },
            { value: "client", label: "Only Master Clients" },
            { value: "team_member", label: "Only Team Members" },
            { value: "admin", label: "Only Admins" },
          ]}
        />
        <label>Subject</label>
        <Input
          value={bulkMailSubject}
          onChange={(e) => setBulkMailSubject(e.target.value)}
        />
        <label>Message</label>
        <Input.TextArea
          value={bulkMailMessage}
          onChange={(e) => setBulkMailMessage(e.target.value)}
        />
        <Popconfirm
          title="You are about to send a bulk email to all users. Continue?"
          onConfirm={handleBulk}
        >
          <Button
            type="primary"
            className="flex items-center gap-1 mt-2"
            disabled={loading}
          >
            Send
          </Button>
        </Popconfirm>
      </div>
    </>
  );
};

export default Notifications;
