import { Alert, Descriptions, Space, Tag, Divider } from "antd";
import React from "react";

function arraysAreEqual(arr1, arr2) {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays
  arr1.sort();
  arr2.sort();

  // Compare each element in the sorted arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If all elements are equal, the arrays are equal
  return true;
}

// Deep comparison function for objects
function deepEqual(obj1, obj2, shouldCheckExact) {
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    if (!shouldCheckExact) return true;
    return obj1 === obj2;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return arraysAreEqual(obj1, obj2);
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    console.log(keys1.length);
    console.log(keys2.length);
    return false;
  }

  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key], shouldCheckExact)) {
      return false;
    }
  }

  return true;
}

const DynamicDescription = ({ data, oldResult }) => {
  const renderItem = (key, value) => {
    if (Array.isArray(value)) {
      return (
        <div key={key} style={{ marginBottom: 16 }}>
          <strong>{key}:</strong>
          <Space
            className="flex my-2"
            style={{ maxWidth: "100%", flexWrap: "wrap" }}
          >
            {value.map((item, index) => {
              if (typeof item === "object") {
                return renderNestedItem(item, key);
              } else {
                return (
                  <Tag
                    key={index}
                    color={
                      !oldResult || oldResult?.[key]?.includes?.(item) ? null : "red"
                    }
                    style={{ marginRight: 8 }}
                  >
                    {item?.includes?.("http") ? (
                      <a href={item} target="_blank" rel="noreferrer noopener">
                        {item}
                      </a>
                    ) : (
                      item
                    )}
                  </Tag>
                );
              }
            })}
          </Space>
        </div>
      );
    } else if (typeof value === "object") {
      return renderNestedItem(value, key);
    } else {
      return (
        <div key={key} style={{ marginBottom: 16 }}>
          <strong>{key}:</strong> {value}
        </div>
      );
    }
  };

  const renderNestedItem = (nestedData, parentKey) => {
    return (
      <div key={parentKey} style={{ marginBottom: 16 }}>
        <Divider>{parentKey.toUpperCase()}</Divider>
        {Object.entries(nestedData).map(([nestedKey, nestedValue]) =>
          renderItem(nestedKey, nestedValue)
        )}
      </div>
    );
  };

  return (
    <>
      {oldResult && (
        <Alert
          type="info"
          message="Changed items will be marked in red color"
          style={{ marginBottom: 16 }}
        />
      )}
      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
        {Object.entries(data).map(([key, value]) => renderItem(key, value))}
      </div>
    </>
  );
};

export default DynamicDescription;
